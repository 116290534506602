.data-list {
    display: grid;
    grid-template-columns: repeat(var(--data-list-columns), minmax(0, 1fr));
    gap: 0.8rem 2rem;

    > dl {
        padding: 0;
        margin: 0;

        > dt {
            padding: 0;
            margin: 0;
            color: var(--color-neutral500);
        }

        > dd {
            padding: 0;
            margin: 0;

            & + dt {
                margin-top: var(--data-list-margin, 0.8rem);
            }
        }
    }
}
