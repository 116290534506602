.accordion__summary {
    display: block;
    list-style: none;

    &::-webkit-details-marker {
        display: none;
    }

    &:hover {
        cursor: pointer;
    }
}
