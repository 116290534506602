.card {
    background-color: var(--color-neutral100);
    box-shadow: var(--shadow-down);
}

.card__header {
    padding: var(--card-header-padding, 2rem 2rem 0);
}

.card__body {
    padding: var(--card-body-padding, 2rem);
}

.card__footer {
    padding: var(--card-footer-padding, 0 2rem 2rem);
}

.card__actions {
    padding: var(--card-actions-padding, 1rem 2rem);
    border-top: var(--card-actions-border, 1px solid var(--color-neutral300));
}
