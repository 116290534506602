@use "sass:map";

body {
    --font-size: 1.6rem;
    --line-height: 150%;
    background-color: var(--color-sand);

    @media (max-width: map.get($breakpoints, "sm")) {
        --font-size: 1.4rem;
        --line-height: 150%;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
}

h1 {
    font-size: 3.2rem;
    line-height: 125%;

    @media (max-width: map.get($breakpoints, "sm")) {
        font-size: 2.4rem;
        line-height: 125%;
    }
}

h2 {
    font-size: 2.4rem;
    line-height: 125%;

    @media (max-width: map.get($breakpoints, "sm")) {
        font-size: 2rem;
        line-height: 125%;
    }
}

h3 {
    font-size: 2rem;
    line-height: 125%;

    @media (max-width: map.get($breakpoints, "sm")) {
        font-size: 1.6rem;
        line-height: 125%;
    }
}

small {
    font-size: 75%;
    line-height: 150%;
}
