.badge--success {
  @extend .badge;
  background-color: var(--color-success);
  color: var(--color-neutral100);
}

.badge--task {
    @extend .badge;
    background-color: var(--status-color, transparent);
    color: var(--color-neutral100);
}
