.table-wrapper {
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
}

.table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

td {
    padding: 0.8rem 1.6rem;
}
