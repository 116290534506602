.card__header {
    --card-header-padding: 1rem 2rem 0;
    display: flex;
    align-items: center;
    min-height: 5rem;

    &:has(* + *) {
        justify-content: space-between;
        gap: 1.6rem;
    }

    &:has(.btn--icon) {
        --card-header-padding: 1rem 1rem 0 2rem;
    }
}

.card--task {
    @extend .card;
    border-left: 5px solid;
    border-color: var(--status-color);
    cursor: pointer;
}
