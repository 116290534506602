.m {
  @each $unit in map-keys($spacing) {
    &-#{$unit} {
      margin-top: map-get($spacing, $unit);
      margin-bottom: map-get($spacing, $unit);
      margin-left: map-get($spacing, $unit);
      margin-right: map-get($spacing, $unit);
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @each $unit in map-keys($spacing) {
        &-#{$unit}--#{$breakpoint} {
          margin-top: map-get($spacing, $unit);
          margin-bottom: map-get($spacing, $unit);
          margin-left: map-get($spacing, $unit);
          margin-right: map-get($spacing, $unit);
        }
      }
    }
  }
}

.my {
  @each $unit in map-keys($spacing) {
    &-#{$unit} {
      margin-top: map-get($spacing, $unit);
      margin-bottom: map-get($spacing, $unit);
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @each $unit in map-keys($spacing) {
        &-#{$unit}--#{$breakpoint} {
          margin-top: map-get($spacing, $unit);
          margin-bottom: map-get($spacing, $unit);
        }
      }
    }
  }
}

.mx {
  @each $unit in map-keys($spacing) {
    &-#{$unit} {
      margin-left: map-get($spacing, $unit);
      margin-right: map-get($spacing, $unit);
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @each $unit in map-keys($spacing) {
        &-#{$unit}--#{$breakpoint} {
          margin-left: map-get($spacing, $unit);
          margin-right: map-get($spacing, $unit);
        }
      }
    }
  }
}

.mt {
  @each $unit in map-keys($spacing) {
    &-#{$unit} {
      margin-top: map-get($spacing, $unit);
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @each $unit in map-keys($spacing) {
        &-#{$unit}--#{$breakpoint} {
          margin-top: map-get($spacing, $unit);
        }
      }
    }
  }
}

.mb {
  @each $unit in map-keys($spacing) {
    &-#{$unit} {
      margin-bottom: map-get($spacing, $unit);
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @each $unit in map-keys($spacing) {
        &-#{$unit}--#{$breakpoint} {
          margin-bottom: map-get($spacing, $unit);
        }
      }
    }
  }
}

.ml {
  @each $unit in map-keys($spacing) {
    &-#{$unit} {
      margin-left: map-get($spacing, $unit);
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @each $unit in map-keys($spacing) {
        &-#{$unit}--#{$breakpoint} {
          margin-left: map-get($spacing, $unit);
        }
      }
    }
  }
}

.mr {
  @each $unit in map-keys($spacing) {
    &-#{$unit} {
      margin-right: map-get($spacing, $unit);
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @each $unit in map-keys($spacing) {
        &-#{$unit}--#{$breakpoint} {
          margin-right: map-get($spacing, $unit);
        }
      }
    }
  }
}
