.attachments {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
    gap: 1.6rem;
}

.attachment {
    display: grid;
    grid-template-columns: 12rem minmax(0, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-template-areas: "image body";
    border: 1px solid var(--color-neutral300);
}

.attachment__image {
    grid-area: image;
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
    padding: 0.8rem;
    cursor: pointer;
}

.attachment__icon {
    grid-area: image;
    --icon-width: 3.2rem;
    --icon-height: 3.2rem;
    padding: 0.8rem;
    width: 100%;
    aspect-ratio: 1;
    background-color: var(--color-neutral200);
    display: grid;
    justify-content: center;
    align-content: center;
}

.attachment__body {
    grid-area: body;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1.6rem;
    background-color: var(--color-neutral100);
}

.attachment__delete {
    grid-area: body;
    justify-self: end;
    margin: 0.8rem 0.8rem 0 0;
}

.attachment__download {
    grid-area: body;
    justify-self: end;
    margin: 0.8rem 0.8rem 0 0;
}
