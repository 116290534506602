.flex {
    display: flex;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                display: flex;
            }
        }
    }
}

.inline-flex {
    display: inline-flex;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                display: inline-flex;
            }
        }
    }
}

.flex-row {
    flex-direction: row;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                flex-direction: row;
            }
        }
    }
}

.flex-row-reverse {
    flex-direction: row-reverse;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                flex-direction: row-reverse;
            }
        }
    }
}

.flex-column {
    flex-direction: column;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                flex-direction: column;
            }
        }
    }
}

.flex-column-reverse {
    flex-direction: column-reverse;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                flex-direction: column-reverse;
            }
        }
    }
}

.flex-wrap {
    flex-wrap: wrap;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                flex-wrap: wrap;
            }
        }
    }
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                flex-wrap: wrap-reverse;
            }
        }
    }
}

.flex-nowrap {
    flex-wrap: nowrap;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                flex-wrap: nowrap;
            }
        }
    }
}

.justify-content-start {
    justify-content: flex-start;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                justify-content: flex-start;
            }
        }
    }
}

.justify-content-end {
    justify-content: flex-end;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                justify-content: flex-end;
            }
        }
    }
}

.justify-content-center {
    justify-content: center;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                justify-content: center;
            }
        }
    }
}

.justify-content-between {
    justify-content: space-between;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                justify-content: space-between;
            }
        }
    }
}

.justify-content-around {
    justify-content: space-around;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                justify-content: space-around;
            }
        }
    }
}

.justify-content-stretch {
    justify-content: stretch;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                justify-content: stretch;
            }
        }
    }
}

.align-items-start {
    align-items: flex-start;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                align-items: flex-start;
            }
        }
    }
}

.align-items-end {
    align-items: flex-end;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                align-items: flex-end;
            }
        }
    }
}

.align-items-center {
    align-items: center;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                align-items: center;
            }
        }
    }
}

.align-items-stretch {
    align-items: stretch;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                align-items: stretch;
            }
        }
    }
}

.align-content-start {
    align-content: flex-start;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                align-content: flex-start;
            }
        }
    }
}

.align-content-end {
    align-content: flex-end;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                align-content: flex-end;
            }
        }
    }
}

.align-content-center {
    align-content: center;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                align-content: center;
            }
        }
    }
}

.align-content-stretch {
    align-content: stretch;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                align-content: stretch;
            }
        }
    }
}

.align-self-start {
    align-self: flex-start;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                align-self: flex-start;
            }
        }
    }
}

.align-self-end {
    align-self: flex-end;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                align-self: flex-end;
            }
        }
    }
}

.align-self-center {
    align-self: center;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                align-self: center;
            }
        }
    }
}

.align-self-stretch {
    align-self: stretch;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &--#{$breakpoint} {
                align-self: stretch;
            }
        }
    }
}

.gap {
    @each $unit in map-keys($spacing) {
        &-#{$unit} {
            gap: map-get($spacing, $unit);
        }
    }

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            @each $unit in map-keys($spacing) {
                &-#{$unit}--#{$breakpoint} {
                    gap: map-get($spacing, $unit);
                }
            }
        }
    }
}
