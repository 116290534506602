@use "sass:map";

.btn {
    --btn-border-radius: 0;
    --btn-padding: 0 2rem;
    --btn-height: 4rem;
    --btn-font-weight: 500;
    --btn-color: var(--color-neutral900);

    @media (max-width: map.get($breakpoints, "sm")) {
        > span {
            display: none;
        }

        &:has(> span) {
            --btn-width: 4rem;
            --btn-padding: 0;
        }
    }
}

.btn--yellow {
    @extend .btn;
    --btn-background-color: var(--color-yellow);
}

.btn--yellow__reverse {
    @extend .btn;
    --btn-background-color: var(--color-neutral100);

    &[disabled] {
        background-color: var(--color-yellow);
        color: var(--color-neutral900);
    }
}

.btn--white {
    @extend .btn;
    --btn-background-color: var(--color-neutral100);
}

.btn--lightgrey {
    @extend .btn;
    --btn-background-color: var(--color-neutral200);
}

.btn--icon {
    @extend .btn;
    --btn-width: 4rem;
    --btn-padding: 0;
}

.btn--floating-action {
    @extend .btn;
    --btn-width: 4.8rem;
    --btn-height: 4.8rem;
    --btn-padding: 0;
    --btn-background-color: var(--color-yellow);
    box-shadow: var(--shadow-down);
}

.btn--google-maps {
    @extend .btn;
    --btn-padding: 0;
    --btn-width: 3.2rem;
    --btn-height: 3.2rem;
    --btn-icon-width: 3.2rem;
    --btn-icon-height: 3.2rem;

    &:hover:before,
    &.is-active:before {
        --btn-background-color-hover: transparent;
    }
}
