*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

html,
body {
    width: 100%;
    height: 100%;
    overscroll-behavior: none;
}

body {
    background-color: white;
    font-family: var(--font-family, sans-serif);
    font-size: var(--font-size, 1.6rem);
    font-weight: var(--font-weight, 400);
    line-height: var(--line-height, 150%);
    color: var(--color, black);
    overflow: hidden;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    margin: 0;
    padding: 0;
}

ol,
ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
}

svg,
img {
    display: block;
    max-width: 100%;
    height: auto;
}
