.grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-auto-rows: auto;
  gap: var(--grid-gap-y, var(--grid-gap, 2.4rem)) var(--grid-gap-x, var(--grid-gap, 2.4rem));
}

.grid__item-1 {
  grid-column: auto/span 1;
}
.grid__item-2 {
  grid-column: auto/span 2;
}
.grid__item-3 {
  grid-column: auto/span 3;
}
.grid__item-4 {
  grid-column: auto/span 4;
}
.grid__item-5 {
  grid-column: auto/span 5;
}
.grid__item-6 {
  grid-column: auto/span 6;
}
.grid__item-7 {
  grid-column: auto/span 7;
}
.grid__item-8 {
  grid-column: auto/span 8;
}
.grid__item-9 {
  grid-column: auto/span 9;
}
.grid__item-10 {
  grid-column: auto/span 10;
}
.grid__item-11 {
  grid-column: auto/span 11;
}
.grid__item-12 {
  grid-column: auto/span 12;
}
@media only screen and (min-width: 360px) {
  .grid__item-1--xs {
    grid-column: auto/span 1;
  }
  .grid__item-2--xs {
    grid-column: auto/span 2;
  }
  .grid__item-3--xs {
    grid-column: auto/span 3;
  }
  .grid__item-4--xs {
    grid-column: auto/span 4;
  }
  .grid__item-5--xs {
    grid-column: auto/span 5;
  }
  .grid__item-6--xs {
    grid-column: auto/span 6;
  }
  .grid__item-7--xs {
    grid-column: auto/span 7;
  }
  .grid__item-8--xs {
    grid-column: auto/span 8;
  }
  .grid__item-9--xs {
    grid-column: auto/span 9;
  }
  .grid__item-10--xs {
    grid-column: auto/span 10;
  }
  .grid__item-11--xs {
    grid-column: auto/span 11;
  }
  .grid__item-12--xs {
    grid-column: auto/span 12;
  }
}
@media only screen and (min-width: 640px) {
  .grid__item-1--sm {
    grid-column: auto/span 1;
  }
  .grid__item-2--sm {
    grid-column: auto/span 2;
  }
  .grid__item-3--sm {
    grid-column: auto/span 3;
  }
  .grid__item-4--sm {
    grid-column: auto/span 4;
  }
  .grid__item-5--sm {
    grid-column: auto/span 5;
  }
  .grid__item-6--sm {
    grid-column: auto/span 6;
  }
  .grid__item-7--sm {
    grid-column: auto/span 7;
  }
  .grid__item-8--sm {
    grid-column: auto/span 8;
  }
  .grid__item-9--sm {
    grid-column: auto/span 9;
  }
  .grid__item-10--sm {
    grid-column: auto/span 10;
  }
  .grid__item-11--sm {
    grid-column: auto/span 11;
  }
  .grid__item-12--sm {
    grid-column: auto/span 12;
  }
}
@media only screen and (min-width: 720px) {
  .grid__item-1--md {
    grid-column: auto/span 1;
  }
  .grid__item-2--md {
    grid-column: auto/span 2;
  }
  .grid__item-3--md {
    grid-column: auto/span 3;
  }
  .grid__item-4--md {
    grid-column: auto/span 4;
  }
  .grid__item-5--md {
    grid-column: auto/span 5;
  }
  .grid__item-6--md {
    grid-column: auto/span 6;
  }
  .grid__item-7--md {
    grid-column: auto/span 7;
  }
  .grid__item-8--md {
    grid-column: auto/span 8;
  }
  .grid__item-9--md {
    grid-column: auto/span 9;
  }
  .grid__item-10--md {
    grid-column: auto/span 10;
  }
  .grid__item-11--md {
    grid-column: auto/span 11;
  }
  .grid__item-12--md {
    grid-column: auto/span 12;
  }
}
@media only screen and (min-width: 960px) {
  .grid__item-1--lg {
    grid-column: auto/span 1;
  }
  .grid__item-2--lg {
    grid-column: auto/span 2;
  }
  .grid__item-3--lg {
    grid-column: auto/span 3;
  }
  .grid__item-4--lg {
    grid-column: auto/span 4;
  }
  .grid__item-5--lg {
    grid-column: auto/span 5;
  }
  .grid__item-6--lg {
    grid-column: auto/span 6;
  }
  .grid__item-7--lg {
    grid-column: auto/span 7;
  }
  .grid__item-8--lg {
    grid-column: auto/span 8;
  }
  .grid__item-9--lg {
    grid-column: auto/span 9;
  }
  .grid__item-10--lg {
    grid-column: auto/span 10;
  }
  .grid__item-11--lg {
    grid-column: auto/span 11;
  }
  .grid__item-12--lg {
    grid-column: auto/span 12;
  }
}
@media only screen and (min-width: 1280px) {
  .grid__item-1--xl {
    grid-column: auto/span 1;
  }
  .grid__item-2--xl {
    grid-column: auto/span 2;
  }
  .grid__item-3--xl {
    grid-column: auto/span 3;
  }
  .grid__item-4--xl {
    grid-column: auto/span 4;
  }
  .grid__item-5--xl {
    grid-column: auto/span 5;
  }
  .grid__item-6--xl {
    grid-column: auto/span 6;
  }
  .grid__item-7--xl {
    grid-column: auto/span 7;
  }
  .grid__item-8--xl {
    grid-column: auto/span 8;
  }
  .grid__item-9--xl {
    grid-column: auto/span 9;
  }
  .grid__item-10--xl {
    grid-column: auto/span 10;
  }
  .grid__item-11--xl {
    grid-column: auto/span 11;
  }
  .grid__item-12--xl {
    grid-column: auto/span 12;
  }
}
@media only screen and (min-width: 1600px) {
  .grid__item-1--xxl {
    grid-column: auto/span 1;
  }
  .grid__item-2--xxl {
    grid-column: auto/span 2;
  }
  .grid__item-3--xxl {
    grid-column: auto/span 3;
  }
  .grid__item-4--xxl {
    grid-column: auto/span 4;
  }
  .grid__item-5--xxl {
    grid-column: auto/span 5;
  }
  .grid__item-6--xxl {
    grid-column: auto/span 6;
  }
  .grid__item-7--xxl {
    grid-column: auto/span 7;
  }
  .grid__item-8--xxl {
    grid-column: auto/span 8;
  }
  .grid__item-9--xxl {
    grid-column: auto/span 9;
  }
  .grid__item-10--xxl {
    grid-column: auto/span 10;
  }
  .grid__item-11--xxl {
    grid-column: auto/span 11;
  }
  .grid__item-12--xxl {
    grid-column: auto/span 12;
  }
}

.flex {
  display: flex;
}
@media only screen and (min-width: 360px) {
  .flex--xs {
    display: flex;
  }
}
@media only screen and (min-width: 640px) {
  .flex--sm {
    display: flex;
  }
}
@media only screen and (min-width: 720px) {
  .flex--md {
    display: flex;
  }
}
@media only screen and (min-width: 960px) {
  .flex--lg {
    display: flex;
  }
}
@media only screen and (min-width: 1280px) {
  .flex--xl {
    display: flex;
  }
}
@media only screen and (min-width: 1600px) {
  .flex--xxl {
    display: flex;
  }
}

.inline-flex {
  display: inline-flex;
}
@media only screen and (min-width: 360px) {
  .inline-flex--xs {
    display: inline-flex;
  }
}
@media only screen and (min-width: 640px) {
  .inline-flex--sm {
    display: inline-flex;
  }
}
@media only screen and (min-width: 720px) {
  .inline-flex--md {
    display: inline-flex;
  }
}
@media only screen and (min-width: 960px) {
  .inline-flex--lg {
    display: inline-flex;
  }
}
@media only screen and (min-width: 1280px) {
  .inline-flex--xl {
    display: inline-flex;
  }
}
@media only screen and (min-width: 1600px) {
  .inline-flex--xxl {
    display: inline-flex;
  }
}

.flex-row {
  flex-direction: row;
}
@media only screen and (min-width: 360px) {
  .flex-row--xs {
    flex-direction: row;
  }
}
@media only screen and (min-width: 640px) {
  .flex-row--sm {
    flex-direction: row;
  }
}
@media only screen and (min-width: 720px) {
  .flex-row--md {
    flex-direction: row;
  }
}
@media only screen and (min-width: 960px) {
  .flex-row--lg {
    flex-direction: row;
  }
}
@media only screen and (min-width: 1280px) {
  .flex-row--xl {
    flex-direction: row;
  }
}
@media only screen and (min-width: 1600px) {
  .flex-row--xxl {
    flex-direction: row;
  }
}

.flex-row-reverse {
  flex-direction: row-reverse;
}
@media only screen and (min-width: 360px) {
  .flex-row-reverse--xs {
    flex-direction: row-reverse;
  }
}
@media only screen and (min-width: 640px) {
  .flex-row-reverse--sm {
    flex-direction: row-reverse;
  }
}
@media only screen and (min-width: 720px) {
  .flex-row-reverse--md {
    flex-direction: row-reverse;
  }
}
@media only screen and (min-width: 960px) {
  .flex-row-reverse--lg {
    flex-direction: row-reverse;
  }
}
@media only screen and (min-width: 1280px) {
  .flex-row-reverse--xl {
    flex-direction: row-reverse;
  }
}
@media only screen and (min-width: 1600px) {
  .flex-row-reverse--xxl {
    flex-direction: row-reverse;
  }
}

.flex-column {
  flex-direction: column;
}
@media only screen and (min-width: 360px) {
  .flex-column--xs {
    flex-direction: column;
  }
}
@media only screen and (min-width: 640px) {
  .flex-column--sm {
    flex-direction: column;
  }
}
@media only screen and (min-width: 720px) {
  .flex-column--md {
    flex-direction: column;
  }
}
@media only screen and (min-width: 960px) {
  .flex-column--lg {
    flex-direction: column;
  }
}
@media only screen and (min-width: 1280px) {
  .flex-column--xl {
    flex-direction: column;
  }
}
@media only screen and (min-width: 1600px) {
  .flex-column--xxl {
    flex-direction: column;
  }
}

.flex-column-reverse {
  flex-direction: column-reverse;
}
@media only screen and (min-width: 360px) {
  .flex-column-reverse--xs {
    flex-direction: column-reverse;
  }
}
@media only screen and (min-width: 640px) {
  .flex-column-reverse--sm {
    flex-direction: column-reverse;
  }
}
@media only screen and (min-width: 720px) {
  .flex-column-reverse--md {
    flex-direction: column-reverse;
  }
}
@media only screen and (min-width: 960px) {
  .flex-column-reverse--lg {
    flex-direction: column-reverse;
  }
}
@media only screen and (min-width: 1280px) {
  .flex-column-reverse--xl {
    flex-direction: column-reverse;
  }
}
@media only screen and (min-width: 1600px) {
  .flex-column-reverse--xxl {
    flex-direction: column-reverse;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}
@media only screen and (min-width: 360px) {
  .flex-wrap--xs {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 640px) {
  .flex-wrap--sm {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 720px) {
  .flex-wrap--md {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 960px) {
  .flex-wrap--lg {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 1280px) {
  .flex-wrap--xl {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 1600px) {
  .flex-wrap--xxl {
    flex-wrap: wrap;
  }
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}
@media only screen and (min-width: 360px) {
  .flex-wrap-reverse--xs {
    flex-wrap: wrap-reverse;
  }
}
@media only screen and (min-width: 640px) {
  .flex-wrap-reverse--sm {
    flex-wrap: wrap-reverse;
  }
}
@media only screen and (min-width: 720px) {
  .flex-wrap-reverse--md {
    flex-wrap: wrap-reverse;
  }
}
@media only screen and (min-width: 960px) {
  .flex-wrap-reverse--lg {
    flex-wrap: wrap-reverse;
  }
}
@media only screen and (min-width: 1280px) {
  .flex-wrap-reverse--xl {
    flex-wrap: wrap-reverse;
  }
}
@media only screen and (min-width: 1600px) {
  .flex-wrap-reverse--xxl {
    flex-wrap: wrap-reverse;
  }
}

.flex-nowrap {
  flex-wrap: nowrap;
}
@media only screen and (min-width: 360px) {
  .flex-nowrap--xs {
    flex-wrap: nowrap;
  }
}
@media only screen and (min-width: 640px) {
  .flex-nowrap--sm {
    flex-wrap: nowrap;
  }
}
@media only screen and (min-width: 720px) {
  .flex-nowrap--md {
    flex-wrap: nowrap;
  }
}
@media only screen and (min-width: 960px) {
  .flex-nowrap--lg {
    flex-wrap: nowrap;
  }
}
@media only screen and (min-width: 1280px) {
  .flex-nowrap--xl {
    flex-wrap: nowrap;
  }
}
@media only screen and (min-width: 1600px) {
  .flex-nowrap--xxl {
    flex-wrap: nowrap;
  }
}

.justify-content-start {
  justify-content: flex-start;
}
@media only screen and (min-width: 360px) {
  .justify-content-start--xs {
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 640px) {
  .justify-content-start--sm {
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 720px) {
  .justify-content-start--md {
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 960px) {
  .justify-content-start--lg {
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 1280px) {
  .justify-content-start--xl {
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 1600px) {
  .justify-content-start--xxl {
    justify-content: flex-start;
  }
}

.justify-content-end {
  justify-content: flex-end;
}
@media only screen and (min-width: 360px) {
  .justify-content-end--xs {
    justify-content: flex-end;
  }
}
@media only screen and (min-width: 640px) {
  .justify-content-end--sm {
    justify-content: flex-end;
  }
}
@media only screen and (min-width: 720px) {
  .justify-content-end--md {
    justify-content: flex-end;
  }
}
@media only screen and (min-width: 960px) {
  .justify-content-end--lg {
    justify-content: flex-end;
  }
}
@media only screen and (min-width: 1280px) {
  .justify-content-end--xl {
    justify-content: flex-end;
  }
}
@media only screen and (min-width: 1600px) {
  .justify-content-end--xxl {
    justify-content: flex-end;
  }
}

.justify-content-center {
  justify-content: center;
}
@media only screen and (min-width: 360px) {
  .justify-content-center--xs {
    justify-content: center;
  }
}
@media only screen and (min-width: 640px) {
  .justify-content-center--sm {
    justify-content: center;
  }
}
@media only screen and (min-width: 720px) {
  .justify-content-center--md {
    justify-content: center;
  }
}
@media only screen and (min-width: 960px) {
  .justify-content-center--lg {
    justify-content: center;
  }
}
@media only screen and (min-width: 1280px) {
  .justify-content-center--xl {
    justify-content: center;
  }
}
@media only screen and (min-width: 1600px) {
  .justify-content-center--xxl {
    justify-content: center;
  }
}

.justify-content-between {
  justify-content: space-between;
}
@media only screen and (min-width: 360px) {
  .justify-content-between--xs {
    justify-content: space-between;
  }
}
@media only screen and (min-width: 640px) {
  .justify-content-between--sm {
    justify-content: space-between;
  }
}
@media only screen and (min-width: 720px) {
  .justify-content-between--md {
    justify-content: space-between;
  }
}
@media only screen and (min-width: 960px) {
  .justify-content-between--lg {
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1280px) {
  .justify-content-between--xl {
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1600px) {
  .justify-content-between--xxl {
    justify-content: space-between;
  }
}

.justify-content-around {
  justify-content: space-around;
}
@media only screen and (min-width: 360px) {
  .justify-content-around--xs {
    justify-content: space-around;
  }
}
@media only screen and (min-width: 640px) {
  .justify-content-around--sm {
    justify-content: space-around;
  }
}
@media only screen and (min-width: 720px) {
  .justify-content-around--md {
    justify-content: space-around;
  }
}
@media only screen and (min-width: 960px) {
  .justify-content-around--lg {
    justify-content: space-around;
  }
}
@media only screen and (min-width: 1280px) {
  .justify-content-around--xl {
    justify-content: space-around;
  }
}
@media only screen and (min-width: 1600px) {
  .justify-content-around--xxl {
    justify-content: space-around;
  }
}

.justify-content-stretch {
  justify-content: stretch;
}
@media only screen and (min-width: 360px) {
  .justify-content-stretch--xs {
    justify-content: stretch;
  }
}
@media only screen and (min-width: 640px) {
  .justify-content-stretch--sm {
    justify-content: stretch;
  }
}
@media only screen and (min-width: 720px) {
  .justify-content-stretch--md {
    justify-content: stretch;
  }
}
@media only screen and (min-width: 960px) {
  .justify-content-stretch--lg {
    justify-content: stretch;
  }
}
@media only screen and (min-width: 1280px) {
  .justify-content-stretch--xl {
    justify-content: stretch;
  }
}
@media only screen and (min-width: 1600px) {
  .justify-content-stretch--xxl {
    justify-content: stretch;
  }
}

.align-items-start {
  align-items: flex-start;
}
@media only screen and (min-width: 360px) {
  .align-items-start--xs {
    align-items: flex-start;
  }
}
@media only screen and (min-width: 640px) {
  .align-items-start--sm {
    align-items: flex-start;
  }
}
@media only screen and (min-width: 720px) {
  .align-items-start--md {
    align-items: flex-start;
  }
}
@media only screen and (min-width: 960px) {
  .align-items-start--lg {
    align-items: flex-start;
  }
}
@media only screen and (min-width: 1280px) {
  .align-items-start--xl {
    align-items: flex-start;
  }
}
@media only screen and (min-width: 1600px) {
  .align-items-start--xxl {
    align-items: flex-start;
  }
}

.align-items-end {
  align-items: flex-end;
}
@media only screen and (min-width: 360px) {
  .align-items-end--xs {
    align-items: flex-end;
  }
}
@media only screen and (min-width: 640px) {
  .align-items-end--sm {
    align-items: flex-end;
  }
}
@media only screen and (min-width: 720px) {
  .align-items-end--md {
    align-items: flex-end;
  }
}
@media only screen and (min-width: 960px) {
  .align-items-end--lg {
    align-items: flex-end;
  }
}
@media only screen and (min-width: 1280px) {
  .align-items-end--xl {
    align-items: flex-end;
  }
}
@media only screen and (min-width: 1600px) {
  .align-items-end--xxl {
    align-items: flex-end;
  }
}

.align-items-center {
  align-items: center;
}
@media only screen and (min-width: 360px) {
  .align-items-center--xs {
    align-items: center;
  }
}
@media only screen and (min-width: 640px) {
  .align-items-center--sm {
    align-items: center;
  }
}
@media only screen and (min-width: 720px) {
  .align-items-center--md {
    align-items: center;
  }
}
@media only screen and (min-width: 960px) {
  .align-items-center--lg {
    align-items: center;
  }
}
@media only screen and (min-width: 1280px) {
  .align-items-center--xl {
    align-items: center;
  }
}
@media only screen and (min-width: 1600px) {
  .align-items-center--xxl {
    align-items: center;
  }
}

.align-items-stretch {
  align-items: stretch;
}
@media only screen and (min-width: 360px) {
  .align-items-stretch--xs {
    align-items: stretch;
  }
}
@media only screen and (min-width: 640px) {
  .align-items-stretch--sm {
    align-items: stretch;
  }
}
@media only screen and (min-width: 720px) {
  .align-items-stretch--md {
    align-items: stretch;
  }
}
@media only screen and (min-width: 960px) {
  .align-items-stretch--lg {
    align-items: stretch;
  }
}
@media only screen and (min-width: 1280px) {
  .align-items-stretch--xl {
    align-items: stretch;
  }
}
@media only screen and (min-width: 1600px) {
  .align-items-stretch--xxl {
    align-items: stretch;
  }
}

.align-content-start {
  align-content: flex-start;
}
@media only screen and (min-width: 360px) {
  .align-content-start--xs {
    align-content: flex-start;
  }
}
@media only screen and (min-width: 640px) {
  .align-content-start--sm {
    align-content: flex-start;
  }
}
@media only screen and (min-width: 720px) {
  .align-content-start--md {
    align-content: flex-start;
  }
}
@media only screen and (min-width: 960px) {
  .align-content-start--lg {
    align-content: flex-start;
  }
}
@media only screen and (min-width: 1280px) {
  .align-content-start--xl {
    align-content: flex-start;
  }
}
@media only screen and (min-width: 1600px) {
  .align-content-start--xxl {
    align-content: flex-start;
  }
}

.align-content-end {
  align-content: flex-end;
}
@media only screen and (min-width: 360px) {
  .align-content-end--xs {
    align-content: flex-end;
  }
}
@media only screen and (min-width: 640px) {
  .align-content-end--sm {
    align-content: flex-end;
  }
}
@media only screen and (min-width: 720px) {
  .align-content-end--md {
    align-content: flex-end;
  }
}
@media only screen and (min-width: 960px) {
  .align-content-end--lg {
    align-content: flex-end;
  }
}
@media only screen and (min-width: 1280px) {
  .align-content-end--xl {
    align-content: flex-end;
  }
}
@media only screen and (min-width: 1600px) {
  .align-content-end--xxl {
    align-content: flex-end;
  }
}

.align-content-center {
  align-content: center;
}
@media only screen and (min-width: 360px) {
  .align-content-center--xs {
    align-content: center;
  }
}
@media only screen and (min-width: 640px) {
  .align-content-center--sm {
    align-content: center;
  }
}
@media only screen and (min-width: 720px) {
  .align-content-center--md {
    align-content: center;
  }
}
@media only screen and (min-width: 960px) {
  .align-content-center--lg {
    align-content: center;
  }
}
@media only screen and (min-width: 1280px) {
  .align-content-center--xl {
    align-content: center;
  }
}
@media only screen and (min-width: 1600px) {
  .align-content-center--xxl {
    align-content: center;
  }
}

.align-content-stretch {
  align-content: stretch;
}
@media only screen and (min-width: 360px) {
  .align-content-stretch--xs {
    align-content: stretch;
  }
}
@media only screen and (min-width: 640px) {
  .align-content-stretch--sm {
    align-content: stretch;
  }
}
@media only screen and (min-width: 720px) {
  .align-content-stretch--md {
    align-content: stretch;
  }
}
@media only screen and (min-width: 960px) {
  .align-content-stretch--lg {
    align-content: stretch;
  }
}
@media only screen and (min-width: 1280px) {
  .align-content-stretch--xl {
    align-content: stretch;
  }
}
@media only screen and (min-width: 1600px) {
  .align-content-stretch--xxl {
    align-content: stretch;
  }
}

.align-self-start {
  align-self: flex-start;
}
@media only screen and (min-width: 360px) {
  .align-self-start--xs {
    align-self: flex-start;
  }
}
@media only screen and (min-width: 640px) {
  .align-self-start--sm {
    align-self: flex-start;
  }
}
@media only screen and (min-width: 720px) {
  .align-self-start--md {
    align-self: flex-start;
  }
}
@media only screen and (min-width: 960px) {
  .align-self-start--lg {
    align-self: flex-start;
  }
}
@media only screen and (min-width: 1280px) {
  .align-self-start--xl {
    align-self: flex-start;
  }
}
@media only screen and (min-width: 1600px) {
  .align-self-start--xxl {
    align-self: flex-start;
  }
}

.align-self-end {
  align-self: flex-end;
}
@media only screen and (min-width: 360px) {
  .align-self-end--xs {
    align-self: flex-end;
  }
}
@media only screen and (min-width: 640px) {
  .align-self-end--sm {
    align-self: flex-end;
  }
}
@media only screen and (min-width: 720px) {
  .align-self-end--md {
    align-self: flex-end;
  }
}
@media only screen and (min-width: 960px) {
  .align-self-end--lg {
    align-self: flex-end;
  }
}
@media only screen and (min-width: 1280px) {
  .align-self-end--xl {
    align-self: flex-end;
  }
}
@media only screen and (min-width: 1600px) {
  .align-self-end--xxl {
    align-self: flex-end;
  }
}

.align-self-center {
  align-self: center;
}
@media only screen and (min-width: 360px) {
  .align-self-center--xs {
    align-self: center;
  }
}
@media only screen and (min-width: 640px) {
  .align-self-center--sm {
    align-self: center;
  }
}
@media only screen and (min-width: 720px) {
  .align-self-center--md {
    align-self: center;
  }
}
@media only screen and (min-width: 960px) {
  .align-self-center--lg {
    align-self: center;
  }
}
@media only screen and (min-width: 1280px) {
  .align-self-center--xl {
    align-self: center;
  }
}
@media only screen and (min-width: 1600px) {
  .align-self-center--xxl {
    align-self: center;
  }
}

.align-self-stretch {
  align-self: stretch;
}
@media only screen and (min-width: 360px) {
  .align-self-stretch--xs {
    align-self: stretch;
  }
}
@media only screen and (min-width: 640px) {
  .align-self-stretch--sm {
    align-self: stretch;
  }
}
@media only screen and (min-width: 720px) {
  .align-self-stretch--md {
    align-self: stretch;
  }
}
@media only screen and (min-width: 960px) {
  .align-self-stretch--lg {
    align-self: stretch;
  }
}
@media only screen and (min-width: 1280px) {
  .align-self-stretch--xl {
    align-self: stretch;
  }
}
@media only screen and (min-width: 1600px) {
  .align-self-stretch--xxl {
    align-self: stretch;
  }
}

.gap-auto {
  gap: auto;
}
.gap-0 {
  gap: 0;
}
.gap-8 {
  gap: 0.8rem;
}
.gap-16 {
  gap: 1.6rem;
}
.gap-20 {
  gap: 2rem;
}
.gap-24 {
  gap: 2.4rem;
}
.gap-40 {
  gap: 4rem;
}
.gap-48 {
  gap: 4.8rem;
}
@media only screen and (min-width: 360px) {
  .gap-auto--xs {
    gap: auto;
  }
  .gap-0--xs {
    gap: 0;
  }
  .gap-8--xs {
    gap: 0.8rem;
  }
  .gap-16--xs {
    gap: 1.6rem;
  }
  .gap-20--xs {
    gap: 2rem;
  }
  .gap-24--xs {
    gap: 2.4rem;
  }
  .gap-40--xs {
    gap: 4rem;
  }
  .gap-48--xs {
    gap: 4.8rem;
  }
}
@media only screen and (min-width: 640px) {
  .gap-auto--sm {
    gap: auto;
  }
  .gap-0--sm {
    gap: 0;
  }
  .gap-8--sm {
    gap: 0.8rem;
  }
  .gap-16--sm {
    gap: 1.6rem;
  }
  .gap-20--sm {
    gap: 2rem;
  }
  .gap-24--sm {
    gap: 2.4rem;
  }
  .gap-40--sm {
    gap: 4rem;
  }
  .gap-48--sm {
    gap: 4.8rem;
  }
}
@media only screen and (min-width: 720px) {
  .gap-auto--md {
    gap: auto;
  }
  .gap-0--md {
    gap: 0;
  }
  .gap-8--md {
    gap: 0.8rem;
  }
  .gap-16--md {
    gap: 1.6rem;
  }
  .gap-20--md {
    gap: 2rem;
  }
  .gap-24--md {
    gap: 2.4rem;
  }
  .gap-40--md {
    gap: 4rem;
  }
  .gap-48--md {
    gap: 4.8rem;
  }
}
@media only screen and (min-width: 960px) {
  .gap-auto--lg {
    gap: auto;
  }
  .gap-0--lg {
    gap: 0;
  }
  .gap-8--lg {
    gap: 0.8rem;
  }
  .gap-16--lg {
    gap: 1.6rem;
  }
  .gap-20--lg {
    gap: 2rem;
  }
  .gap-24--lg {
    gap: 2.4rem;
  }
  .gap-40--lg {
    gap: 4rem;
  }
  .gap-48--lg {
    gap: 4.8rem;
  }
}
@media only screen and (min-width: 1280px) {
  .gap-auto--xl {
    gap: auto;
  }
  .gap-0--xl {
    gap: 0;
  }
  .gap-8--xl {
    gap: 0.8rem;
  }
  .gap-16--xl {
    gap: 1.6rem;
  }
  .gap-20--xl {
    gap: 2rem;
  }
  .gap-24--xl {
    gap: 2.4rem;
  }
  .gap-40--xl {
    gap: 4rem;
  }
  .gap-48--xl {
    gap: 4.8rem;
  }
}
@media only screen and (min-width: 1600px) {
  .gap-auto--xxl {
    gap: auto;
  }
  .gap-0--xxl {
    gap: 0;
  }
  .gap-8--xxl {
    gap: 0.8rem;
  }
  .gap-16--xxl {
    gap: 1.6rem;
  }
  .gap-20--xxl {
    gap: 2rem;
  }
  .gap-24--xxl {
    gap: 2.4rem;
  }
  .gap-40--xxl {
    gap: 4rem;
  }
  .gap-48--xxl {
    gap: 4.8rem;
  }
}

.m-auto {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}
.m-0 {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}
.m-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}
.m-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  margin-left: 1.6rem;
  margin-right: 1.6rem;
}
.m-20 {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}
.m-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  margin-left: 2.4rem;
  margin-right: 2.4rem;
}
.m-40 {
  margin-top: 4rem;
  margin-bottom: 4rem;
  margin-left: 4rem;
  margin-right: 4rem;
}
.m-48 {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;
  margin-left: 4.8rem;
  margin-right: 4.8rem;
}
@media only screen and (min-width: 360px) {
  .m-auto--xs {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .m-0--xs {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .m-8--xs {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .m-16--xs {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .m-20--xs {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .m-24--xs {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .m-40--xs {
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .m-48--xs {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
}
@media only screen and (min-width: 640px) {
  .m-auto--sm {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .m-0--sm {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .m-8--sm {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .m-16--sm {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .m-20--sm {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .m-24--sm {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .m-40--sm {
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .m-48--sm {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
}
@media only screen and (min-width: 720px) {
  .m-auto--md {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .m-0--md {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .m-8--md {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .m-16--md {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .m-20--md {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .m-24--md {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .m-40--md {
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .m-48--md {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
}
@media only screen and (min-width: 960px) {
  .m-auto--lg {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .m-0--lg {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .m-8--lg {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .m-16--lg {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .m-20--lg {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .m-24--lg {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .m-40--lg {
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .m-48--lg {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
}
@media only screen and (min-width: 1280px) {
  .m-auto--xl {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .m-0--xl {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .m-8--xl {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .m-16--xl {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .m-20--xl {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .m-24--xl {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .m-40--xl {
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .m-48--xl {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
}
@media only screen and (min-width: 1600px) {
  .m-auto--xxl {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .m-0--xxl {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .m-8--xxl {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .m-16--xxl {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .m-20--xxl {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .m-24--xxl {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .m-40--xxl {
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .m-48--xxl {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}
.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}
.my-20 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.my-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
}
.my-40 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.my-48 {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;
}
@media only screen and (min-width: 360px) {
  .my-auto--xs {
    margin-top: auto;
    margin-bottom: auto;
  }
  .my-0--xs {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-8--xs {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  .my-16--xs {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .my-20--xs {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .my-24--xs {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  .my-40--xs {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .my-48--xs {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
  }
}
@media only screen and (min-width: 640px) {
  .my-auto--sm {
    margin-top: auto;
    margin-bottom: auto;
  }
  .my-0--sm {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-8--sm {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  .my-16--sm {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .my-20--sm {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .my-24--sm {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  .my-40--sm {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .my-48--sm {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
  }
}
@media only screen and (min-width: 720px) {
  .my-auto--md {
    margin-top: auto;
    margin-bottom: auto;
  }
  .my-0--md {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-8--md {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  .my-16--md {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .my-20--md {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .my-24--md {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  .my-40--md {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .my-48--md {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
  }
}
@media only screen and (min-width: 960px) {
  .my-auto--lg {
    margin-top: auto;
    margin-bottom: auto;
  }
  .my-0--lg {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-8--lg {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  .my-16--lg {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .my-20--lg {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .my-24--lg {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  .my-40--lg {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .my-48--lg {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
  }
}
@media only screen and (min-width: 1280px) {
  .my-auto--xl {
    margin-top: auto;
    margin-bottom: auto;
  }
  .my-0--xl {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-8--xl {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  .my-16--xl {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .my-20--xl {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .my-24--xl {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  .my-40--xl {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .my-48--xl {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
  }
}
@media only screen and (min-width: 1600px) {
  .my-auto--xxl {
    margin-top: auto;
    margin-bottom: auto;
  }
  .my-0--xxl {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-8--xxl {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  .my-16--xxl {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .my-20--xxl {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .my-24--xxl {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  .my-40--xxl {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .my-48--xxl {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}
.mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem;
}
.mx-20 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.mx-24 {
  margin-left: 2.4rem;
  margin-right: 2.4rem;
}
.mx-40 {
  margin-left: 4rem;
  margin-right: 4rem;
}
.mx-48 {
  margin-left: 4.8rem;
  margin-right: 4.8rem;
}
@media only screen and (min-width: 360px) {
  .mx-auto--xs {
    margin-left: auto;
    margin-right: auto;
  }
  .mx-0--xs {
    margin-left: 0;
    margin-right: 0;
  }
  .mx-8--xs {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .mx-16--xs {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .mx-20--xs {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mx-24--xs {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .mx-40--xs {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .mx-48--xs {
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
}
@media only screen and (min-width: 640px) {
  .mx-auto--sm {
    margin-left: auto;
    margin-right: auto;
  }
  .mx-0--sm {
    margin-left: 0;
    margin-right: 0;
  }
  .mx-8--sm {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .mx-16--sm {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .mx-20--sm {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mx-24--sm {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .mx-40--sm {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .mx-48--sm {
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
}
@media only screen and (min-width: 720px) {
  .mx-auto--md {
    margin-left: auto;
    margin-right: auto;
  }
  .mx-0--md {
    margin-left: 0;
    margin-right: 0;
  }
  .mx-8--md {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .mx-16--md {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .mx-20--md {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mx-24--md {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .mx-40--md {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .mx-48--md {
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
}
@media only screen and (min-width: 960px) {
  .mx-auto--lg {
    margin-left: auto;
    margin-right: auto;
  }
  .mx-0--lg {
    margin-left: 0;
    margin-right: 0;
  }
  .mx-8--lg {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .mx-16--lg {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .mx-20--lg {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mx-24--lg {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .mx-40--lg {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .mx-48--lg {
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
}
@media only screen and (min-width: 1280px) {
  .mx-auto--xl {
    margin-left: auto;
    margin-right: auto;
  }
  .mx-0--xl {
    margin-left: 0;
    margin-right: 0;
  }
  .mx-8--xl {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .mx-16--xl {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .mx-20--xl {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mx-24--xl {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .mx-40--xl {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .mx-48--xl {
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
}
@media only screen and (min-width: 1600px) {
  .mx-auto--xxl {
    margin-left: auto;
    margin-right: auto;
  }
  .mx-0--xxl {
    margin-left: 0;
    margin-right: 0;
  }
  .mx-8--xxl {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .mx-16--xxl {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .mx-20--xxl {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mx-24--xxl {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .mx-40--xxl {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .mx-48--xxl {
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
}

.mt-auto {
  margin-top: auto;
}
.mt-0 {
  margin-top: 0;
}
.mt-8 {
  margin-top: 0.8rem;
}
.mt-16 {
  margin-top: 1.6rem;
}
.mt-20 {
  margin-top: 2rem;
}
.mt-24 {
  margin-top: 2.4rem;
}
.mt-40 {
  margin-top: 4rem;
}
.mt-48 {
  margin-top: 4.8rem;
}
@media only screen and (min-width: 360px) {
  .mt-auto--xs {
    margin-top: auto;
  }
  .mt-0--xs {
    margin-top: 0;
  }
  .mt-8--xs {
    margin-top: 0.8rem;
  }
  .mt-16--xs {
    margin-top: 1.6rem;
  }
  .mt-20--xs {
    margin-top: 2rem;
  }
  .mt-24--xs {
    margin-top: 2.4rem;
  }
  .mt-40--xs {
    margin-top: 4rem;
  }
  .mt-48--xs {
    margin-top: 4.8rem;
  }
}
@media only screen and (min-width: 640px) {
  .mt-auto--sm {
    margin-top: auto;
  }
  .mt-0--sm {
    margin-top: 0;
  }
  .mt-8--sm {
    margin-top: 0.8rem;
  }
  .mt-16--sm {
    margin-top: 1.6rem;
  }
  .mt-20--sm {
    margin-top: 2rem;
  }
  .mt-24--sm {
    margin-top: 2.4rem;
  }
  .mt-40--sm {
    margin-top: 4rem;
  }
  .mt-48--sm {
    margin-top: 4.8rem;
  }
}
@media only screen and (min-width: 720px) {
  .mt-auto--md {
    margin-top: auto;
  }
  .mt-0--md {
    margin-top: 0;
  }
  .mt-8--md {
    margin-top: 0.8rem;
  }
  .mt-16--md {
    margin-top: 1.6rem;
  }
  .mt-20--md {
    margin-top: 2rem;
  }
  .mt-24--md {
    margin-top: 2.4rem;
  }
  .mt-40--md {
    margin-top: 4rem;
  }
  .mt-48--md {
    margin-top: 4.8rem;
  }
}
@media only screen and (min-width: 960px) {
  .mt-auto--lg {
    margin-top: auto;
  }
  .mt-0--lg {
    margin-top: 0;
  }
  .mt-8--lg {
    margin-top: 0.8rem;
  }
  .mt-16--lg {
    margin-top: 1.6rem;
  }
  .mt-20--lg {
    margin-top: 2rem;
  }
  .mt-24--lg {
    margin-top: 2.4rem;
  }
  .mt-40--lg {
    margin-top: 4rem;
  }
  .mt-48--lg {
    margin-top: 4.8rem;
  }
}
@media only screen and (min-width: 1280px) {
  .mt-auto--xl {
    margin-top: auto;
  }
  .mt-0--xl {
    margin-top: 0;
  }
  .mt-8--xl {
    margin-top: 0.8rem;
  }
  .mt-16--xl {
    margin-top: 1.6rem;
  }
  .mt-20--xl {
    margin-top: 2rem;
  }
  .mt-24--xl {
    margin-top: 2.4rem;
  }
  .mt-40--xl {
    margin-top: 4rem;
  }
  .mt-48--xl {
    margin-top: 4.8rem;
  }
}
@media only screen and (min-width: 1600px) {
  .mt-auto--xxl {
    margin-top: auto;
  }
  .mt-0--xxl {
    margin-top: 0;
  }
  .mt-8--xxl {
    margin-top: 0.8rem;
  }
  .mt-16--xxl {
    margin-top: 1.6rem;
  }
  .mt-20--xxl {
    margin-top: 2rem;
  }
  .mt-24--xxl {
    margin-top: 2.4rem;
  }
  .mt-40--xxl {
    margin-top: 4rem;
  }
  .mt-48--xxl {
    margin-top: 4.8rem;
  }
}

.mb-auto {
  margin-bottom: auto;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-8 {
  margin-bottom: 0.8rem;
}
.mb-16 {
  margin-bottom: 1.6rem;
}
.mb-20 {
  margin-bottom: 2rem;
}
.mb-24 {
  margin-bottom: 2.4rem;
}
.mb-40 {
  margin-bottom: 4rem;
}
.mb-48 {
  margin-bottom: 4.8rem;
}
@media only screen and (min-width: 360px) {
  .mb-auto--xs {
    margin-bottom: auto;
  }
  .mb-0--xs {
    margin-bottom: 0;
  }
  .mb-8--xs {
    margin-bottom: 0.8rem;
  }
  .mb-16--xs {
    margin-bottom: 1.6rem;
  }
  .mb-20--xs {
    margin-bottom: 2rem;
  }
  .mb-24--xs {
    margin-bottom: 2.4rem;
  }
  .mb-40--xs {
    margin-bottom: 4rem;
  }
  .mb-48--xs {
    margin-bottom: 4.8rem;
  }
}
@media only screen and (min-width: 640px) {
  .mb-auto--sm {
    margin-bottom: auto;
  }
  .mb-0--sm {
    margin-bottom: 0;
  }
  .mb-8--sm {
    margin-bottom: 0.8rem;
  }
  .mb-16--sm {
    margin-bottom: 1.6rem;
  }
  .mb-20--sm {
    margin-bottom: 2rem;
  }
  .mb-24--sm {
    margin-bottom: 2.4rem;
  }
  .mb-40--sm {
    margin-bottom: 4rem;
  }
  .mb-48--sm {
    margin-bottom: 4.8rem;
  }
}
@media only screen and (min-width: 720px) {
  .mb-auto--md {
    margin-bottom: auto;
  }
  .mb-0--md {
    margin-bottom: 0;
  }
  .mb-8--md {
    margin-bottom: 0.8rem;
  }
  .mb-16--md {
    margin-bottom: 1.6rem;
  }
  .mb-20--md {
    margin-bottom: 2rem;
  }
  .mb-24--md {
    margin-bottom: 2.4rem;
  }
  .mb-40--md {
    margin-bottom: 4rem;
  }
  .mb-48--md {
    margin-bottom: 4.8rem;
  }
}
@media only screen and (min-width: 960px) {
  .mb-auto--lg {
    margin-bottom: auto;
  }
  .mb-0--lg {
    margin-bottom: 0;
  }
  .mb-8--lg {
    margin-bottom: 0.8rem;
  }
  .mb-16--lg {
    margin-bottom: 1.6rem;
  }
  .mb-20--lg {
    margin-bottom: 2rem;
  }
  .mb-24--lg {
    margin-bottom: 2.4rem;
  }
  .mb-40--lg {
    margin-bottom: 4rem;
  }
  .mb-48--lg {
    margin-bottom: 4.8rem;
  }
}
@media only screen and (min-width: 1280px) {
  .mb-auto--xl {
    margin-bottom: auto;
  }
  .mb-0--xl {
    margin-bottom: 0;
  }
  .mb-8--xl {
    margin-bottom: 0.8rem;
  }
  .mb-16--xl {
    margin-bottom: 1.6rem;
  }
  .mb-20--xl {
    margin-bottom: 2rem;
  }
  .mb-24--xl {
    margin-bottom: 2.4rem;
  }
  .mb-40--xl {
    margin-bottom: 4rem;
  }
  .mb-48--xl {
    margin-bottom: 4.8rem;
  }
}
@media only screen and (min-width: 1600px) {
  .mb-auto--xxl {
    margin-bottom: auto;
  }
  .mb-0--xxl {
    margin-bottom: 0;
  }
  .mb-8--xxl {
    margin-bottom: 0.8rem;
  }
  .mb-16--xxl {
    margin-bottom: 1.6rem;
  }
  .mb-20--xxl {
    margin-bottom: 2rem;
  }
  .mb-24--xxl {
    margin-bottom: 2.4rem;
  }
  .mb-40--xxl {
    margin-bottom: 4rem;
  }
  .mb-48--xxl {
    margin-bottom: 4.8rem;
  }
}

.ml-auto {
  margin-left: auto;
}
.ml-0 {
  margin-left: 0;
}
.ml-8 {
  margin-left: 0.8rem;
}
.ml-16 {
  margin-left: 1.6rem;
}
.ml-20 {
  margin-left: 2rem;
}
.ml-24 {
  margin-left: 2.4rem;
}
.ml-40 {
  margin-left: 4rem;
}
.ml-48 {
  margin-left: 4.8rem;
}
@media only screen and (min-width: 360px) {
  .ml-auto--xs {
    margin-left: auto;
  }
  .ml-0--xs {
    margin-left: 0;
  }
  .ml-8--xs {
    margin-left: 0.8rem;
  }
  .ml-16--xs {
    margin-left: 1.6rem;
  }
  .ml-20--xs {
    margin-left: 2rem;
  }
  .ml-24--xs {
    margin-left: 2.4rem;
  }
  .ml-40--xs {
    margin-left: 4rem;
  }
  .ml-48--xs {
    margin-left: 4.8rem;
  }
}
@media only screen and (min-width: 640px) {
  .ml-auto--sm {
    margin-left: auto;
  }
  .ml-0--sm {
    margin-left: 0;
  }
  .ml-8--sm {
    margin-left: 0.8rem;
  }
  .ml-16--sm {
    margin-left: 1.6rem;
  }
  .ml-20--sm {
    margin-left: 2rem;
  }
  .ml-24--sm {
    margin-left: 2.4rem;
  }
  .ml-40--sm {
    margin-left: 4rem;
  }
  .ml-48--sm {
    margin-left: 4.8rem;
  }
}
@media only screen and (min-width: 720px) {
  .ml-auto--md {
    margin-left: auto;
  }
  .ml-0--md {
    margin-left: 0;
  }
  .ml-8--md {
    margin-left: 0.8rem;
  }
  .ml-16--md {
    margin-left: 1.6rem;
  }
  .ml-20--md {
    margin-left: 2rem;
  }
  .ml-24--md {
    margin-left: 2.4rem;
  }
  .ml-40--md {
    margin-left: 4rem;
  }
  .ml-48--md {
    margin-left: 4.8rem;
  }
}
@media only screen and (min-width: 960px) {
  .ml-auto--lg {
    margin-left: auto;
  }
  .ml-0--lg {
    margin-left: 0;
  }
  .ml-8--lg {
    margin-left: 0.8rem;
  }
  .ml-16--lg {
    margin-left: 1.6rem;
  }
  .ml-20--lg {
    margin-left: 2rem;
  }
  .ml-24--lg {
    margin-left: 2.4rem;
  }
  .ml-40--lg {
    margin-left: 4rem;
  }
  .ml-48--lg {
    margin-left: 4.8rem;
  }
}
@media only screen and (min-width: 1280px) {
  .ml-auto--xl {
    margin-left: auto;
  }
  .ml-0--xl {
    margin-left: 0;
  }
  .ml-8--xl {
    margin-left: 0.8rem;
  }
  .ml-16--xl {
    margin-left: 1.6rem;
  }
  .ml-20--xl {
    margin-left: 2rem;
  }
  .ml-24--xl {
    margin-left: 2.4rem;
  }
  .ml-40--xl {
    margin-left: 4rem;
  }
  .ml-48--xl {
    margin-left: 4.8rem;
  }
}
@media only screen and (min-width: 1600px) {
  .ml-auto--xxl {
    margin-left: auto;
  }
  .ml-0--xxl {
    margin-left: 0;
  }
  .ml-8--xxl {
    margin-left: 0.8rem;
  }
  .ml-16--xxl {
    margin-left: 1.6rem;
  }
  .ml-20--xxl {
    margin-left: 2rem;
  }
  .ml-24--xxl {
    margin-left: 2.4rem;
  }
  .ml-40--xxl {
    margin-left: 4rem;
  }
  .ml-48--xxl {
    margin-left: 4.8rem;
  }
}

.mr-auto {
  margin-right: auto;
}
.mr-0 {
  margin-right: 0;
}
.mr-8 {
  margin-right: 0.8rem;
}
.mr-16 {
  margin-right: 1.6rem;
}
.mr-20 {
  margin-right: 2rem;
}
.mr-24 {
  margin-right: 2.4rem;
}
.mr-40 {
  margin-right: 4rem;
}
.mr-48 {
  margin-right: 4.8rem;
}
@media only screen and (min-width: 360px) {
  .mr-auto--xs {
    margin-right: auto;
  }
  .mr-0--xs {
    margin-right: 0;
  }
  .mr-8--xs {
    margin-right: 0.8rem;
  }
  .mr-16--xs {
    margin-right: 1.6rem;
  }
  .mr-20--xs {
    margin-right: 2rem;
  }
  .mr-24--xs {
    margin-right: 2.4rem;
  }
  .mr-40--xs {
    margin-right: 4rem;
  }
  .mr-48--xs {
    margin-right: 4.8rem;
  }
}
@media only screen and (min-width: 640px) {
  .mr-auto--sm {
    margin-right: auto;
  }
  .mr-0--sm {
    margin-right: 0;
  }
  .mr-8--sm {
    margin-right: 0.8rem;
  }
  .mr-16--sm {
    margin-right: 1.6rem;
  }
  .mr-20--sm {
    margin-right: 2rem;
  }
  .mr-24--sm {
    margin-right: 2.4rem;
  }
  .mr-40--sm {
    margin-right: 4rem;
  }
  .mr-48--sm {
    margin-right: 4.8rem;
  }
}
@media only screen and (min-width: 720px) {
  .mr-auto--md {
    margin-right: auto;
  }
  .mr-0--md {
    margin-right: 0;
  }
  .mr-8--md {
    margin-right: 0.8rem;
  }
  .mr-16--md {
    margin-right: 1.6rem;
  }
  .mr-20--md {
    margin-right: 2rem;
  }
  .mr-24--md {
    margin-right: 2.4rem;
  }
  .mr-40--md {
    margin-right: 4rem;
  }
  .mr-48--md {
    margin-right: 4.8rem;
  }
}
@media only screen and (min-width: 960px) {
  .mr-auto--lg {
    margin-right: auto;
  }
  .mr-0--lg {
    margin-right: 0;
  }
  .mr-8--lg {
    margin-right: 0.8rem;
  }
  .mr-16--lg {
    margin-right: 1.6rem;
  }
  .mr-20--lg {
    margin-right: 2rem;
  }
  .mr-24--lg {
    margin-right: 2.4rem;
  }
  .mr-40--lg {
    margin-right: 4rem;
  }
  .mr-48--lg {
    margin-right: 4.8rem;
  }
}
@media only screen and (min-width: 1280px) {
  .mr-auto--xl {
    margin-right: auto;
  }
  .mr-0--xl {
    margin-right: 0;
  }
  .mr-8--xl {
    margin-right: 0.8rem;
  }
  .mr-16--xl {
    margin-right: 1.6rem;
  }
  .mr-20--xl {
    margin-right: 2rem;
  }
  .mr-24--xl {
    margin-right: 2.4rem;
  }
  .mr-40--xl {
    margin-right: 4rem;
  }
  .mr-48--xl {
    margin-right: 4.8rem;
  }
}
@media only screen and (min-width: 1600px) {
  .mr-auto--xxl {
    margin-right: auto;
  }
  .mr-0--xxl {
    margin-right: 0;
  }
  .mr-8--xxl {
    margin-right: 0.8rem;
  }
  .mr-16--xxl {
    margin-right: 1.6rem;
  }
  .mr-20--xxl {
    margin-right: 2rem;
  }
  .mr-24--xxl {
    margin-right: 2.4rem;
  }
  .mr-40--xxl {
    margin-right: 4rem;
  }
  .mr-48--xxl {
    margin-right: 4.8rem;
  }
}

.p-auto {
  padding-top: auto;
  padding-bottom: auto;
  padding-left: auto;
  padding-right: auto;
}
.p-0 {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}
.p-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
.p-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.p-20 {
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.p-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}
.p-40 {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 4rem;
  padding-right: 4rem;
}
.p-48 {
  padding-top: 4.8rem;
  padding-bottom: 4.8rem;
  padding-left: 4.8rem;
  padding-right: 4.8rem;
}
@media only screen and (min-width: 360px) {
  .p-auto--xs {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .p-0--xs {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .p-8--xs {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .p-16--xs {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .p-20--xs {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .p-24--xs {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .p-40--xs {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .p-48--xs {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
}
@media only screen and (min-width: 640px) {
  .p-auto--sm {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .p-0--sm {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .p-8--sm {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .p-16--sm {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .p-20--sm {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .p-24--sm {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .p-40--sm {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .p-48--sm {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
}
@media only screen and (min-width: 720px) {
  .p-auto--md {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .p-0--md {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .p-8--md {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .p-16--md {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .p-20--md {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .p-24--md {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .p-40--md {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .p-48--md {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
}
@media only screen and (min-width: 960px) {
  .p-auto--lg {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .p-0--lg {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .p-8--lg {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .p-16--lg {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .p-20--lg {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .p-24--lg {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .p-40--lg {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .p-48--lg {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
}
@media only screen and (min-width: 1280px) {
  .p-auto--xl {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .p-0--xl {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .p-8--xl {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .p-16--xl {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .p-20--xl {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .p-24--xl {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .p-40--xl {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .p-48--xl {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
}
@media only screen and (min-width: 1600px) {
  .p-auto--xxl {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .p-0--xxl {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .p-8--xxl {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .p-16--xxl {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .p-20--xxl {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .p-24--xxl {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .p-40--xxl {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .p-48--xxl {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
}

.py-auto {
  padding-top: auto;
  padding-bottom: auto;
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}
.py-20 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.py-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
}
.py-40 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.py-48 {
  padding-top: 4.8rem;
  padding-bottom: 4.8rem;
}
@media only screen and (min-width: 360px) {
  .py-auto--xs {
    padding-top: auto;
    padding-bottom: auto;
  }
  .py-0--xs {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-8--xs {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .py-16--xs {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  .py-20--xs {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-24--xs {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
  .py-40--xs {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-48--xs {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
}
@media only screen and (min-width: 640px) {
  .py-auto--sm {
    padding-top: auto;
    padding-bottom: auto;
  }
  .py-0--sm {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-8--sm {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .py-16--sm {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  .py-20--sm {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-24--sm {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
  .py-40--sm {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-48--sm {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
}
@media only screen and (min-width: 720px) {
  .py-auto--md {
    padding-top: auto;
    padding-bottom: auto;
  }
  .py-0--md {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-8--md {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .py-16--md {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  .py-20--md {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-24--md {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
  .py-40--md {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-48--md {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
}
@media only screen and (min-width: 960px) {
  .py-auto--lg {
    padding-top: auto;
    padding-bottom: auto;
  }
  .py-0--lg {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-8--lg {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .py-16--lg {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  .py-20--lg {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-24--lg {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
  .py-40--lg {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-48--lg {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
}
@media only screen and (min-width: 1280px) {
  .py-auto--xl {
    padding-top: auto;
    padding-bottom: auto;
  }
  .py-0--xl {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-8--xl {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .py-16--xl {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  .py-20--xl {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-24--xl {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
  .py-40--xl {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-48--xl {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
}
@media only screen and (min-width: 1600px) {
  .py-auto--xxl {
    padding-top: auto;
    padding-bottom: auto;
  }
  .py-0--xxl {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-8--xxl {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .py-16--xxl {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  .py-20--xxl {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-24--xxl {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
  .py-40--xxl {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-48--xxl {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
}

.px-auto {
  padding-left: auto;
  padding-right: auto;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.px-20 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}
.px-40 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.px-48 {
  padding-left: 4.8rem;
  padding-right: 4.8rem;
}
@media only screen and (min-width: 360px) {
  .px-auto--xs {
    padding-left: auto;
    padding-right: auto;
  }
  .px-0--xs {
    padding-left: 0;
    padding-right: 0;
  }
  .px-8--xs {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .px-16--xs {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .px-20--xs {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .px-24--xs {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .px-40--xs {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .px-48--xs {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
}
@media only screen and (min-width: 640px) {
  .px-auto--sm {
    padding-left: auto;
    padding-right: auto;
  }
  .px-0--sm {
    padding-left: 0;
    padding-right: 0;
  }
  .px-8--sm {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .px-16--sm {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .px-20--sm {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .px-24--sm {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .px-40--sm {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .px-48--sm {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
}
@media only screen and (min-width: 720px) {
  .px-auto--md {
    padding-left: auto;
    padding-right: auto;
  }
  .px-0--md {
    padding-left: 0;
    padding-right: 0;
  }
  .px-8--md {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .px-16--md {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .px-20--md {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .px-24--md {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .px-40--md {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .px-48--md {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
}
@media only screen and (min-width: 960px) {
  .px-auto--lg {
    padding-left: auto;
    padding-right: auto;
  }
  .px-0--lg {
    padding-left: 0;
    padding-right: 0;
  }
  .px-8--lg {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .px-16--lg {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .px-20--lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .px-24--lg {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .px-40--lg {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .px-48--lg {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
}
@media only screen and (min-width: 1280px) {
  .px-auto--xl {
    padding-left: auto;
    padding-right: auto;
  }
  .px-0--xl {
    padding-left: 0;
    padding-right: 0;
  }
  .px-8--xl {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .px-16--xl {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .px-20--xl {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .px-24--xl {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .px-40--xl {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .px-48--xl {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
}
@media only screen and (min-width: 1600px) {
  .px-auto--xxl {
    padding-left: auto;
    padding-right: auto;
  }
  .px-0--xxl {
    padding-left: 0;
    padding-right: 0;
  }
  .px-8--xxl {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .px-16--xxl {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .px-20--xxl {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .px-24--xxl {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .px-40--xxl {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .px-48--xxl {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
}

.pt-auto {
  padding-top: auto;
}
.pt-0 {
  padding-top: 0;
}
.pt-8 {
  padding-top: 0.8rem;
}
.pt-16 {
  padding-top: 1.6rem;
}
.pt-20 {
  padding-top: 2rem;
}
.pt-24 {
  padding-top: 2.4rem;
}
.pt-40 {
  padding-top: 4rem;
}
.pt-48 {
  padding-top: 4.8rem;
}
@media only screen and (min-width: 360px) {
  .pt-auto--xs {
    padding-top: auto;
  }
  .pt-0--xs {
    padding-top: 0;
  }
  .pt-8--xs {
    padding-top: 0.8rem;
  }
  .pt-16--xs {
    padding-top: 1.6rem;
  }
  .pt-20--xs {
    padding-top: 2rem;
  }
  .pt-24--xs {
    padding-top: 2.4rem;
  }
  .pt-40--xs {
    padding-top: 4rem;
  }
  .pt-48--xs {
    padding-top: 4.8rem;
  }
}
@media only screen and (min-width: 640px) {
  .pt-auto--sm {
    padding-top: auto;
  }
  .pt-0--sm {
    padding-top: 0;
  }
  .pt-8--sm {
    padding-top: 0.8rem;
  }
  .pt-16--sm {
    padding-top: 1.6rem;
  }
  .pt-20--sm {
    padding-top: 2rem;
  }
  .pt-24--sm {
    padding-top: 2.4rem;
  }
  .pt-40--sm {
    padding-top: 4rem;
  }
  .pt-48--sm {
    padding-top: 4.8rem;
  }
}
@media only screen and (min-width: 720px) {
  .pt-auto--md {
    padding-top: auto;
  }
  .pt-0--md {
    padding-top: 0;
  }
  .pt-8--md {
    padding-top: 0.8rem;
  }
  .pt-16--md {
    padding-top: 1.6rem;
  }
  .pt-20--md {
    padding-top: 2rem;
  }
  .pt-24--md {
    padding-top: 2.4rem;
  }
  .pt-40--md {
    padding-top: 4rem;
  }
  .pt-48--md {
    padding-top: 4.8rem;
  }
}
@media only screen and (min-width: 960px) {
  .pt-auto--lg {
    padding-top: auto;
  }
  .pt-0--lg {
    padding-top: 0;
  }
  .pt-8--lg {
    padding-top: 0.8rem;
  }
  .pt-16--lg {
    padding-top: 1.6rem;
  }
  .pt-20--lg {
    padding-top: 2rem;
  }
  .pt-24--lg {
    padding-top: 2.4rem;
  }
  .pt-40--lg {
    padding-top: 4rem;
  }
  .pt-48--lg {
    padding-top: 4.8rem;
  }
}
@media only screen and (min-width: 1280px) {
  .pt-auto--xl {
    padding-top: auto;
  }
  .pt-0--xl {
    padding-top: 0;
  }
  .pt-8--xl {
    padding-top: 0.8rem;
  }
  .pt-16--xl {
    padding-top: 1.6rem;
  }
  .pt-20--xl {
    padding-top: 2rem;
  }
  .pt-24--xl {
    padding-top: 2.4rem;
  }
  .pt-40--xl {
    padding-top: 4rem;
  }
  .pt-48--xl {
    padding-top: 4.8rem;
  }
}
@media only screen and (min-width: 1600px) {
  .pt-auto--xxl {
    padding-top: auto;
  }
  .pt-0--xxl {
    padding-top: 0;
  }
  .pt-8--xxl {
    padding-top: 0.8rem;
  }
  .pt-16--xxl {
    padding-top: 1.6rem;
  }
  .pt-20--xxl {
    padding-top: 2rem;
  }
  .pt-24--xxl {
    padding-top: 2.4rem;
  }
  .pt-40--xxl {
    padding-top: 4rem;
  }
  .pt-48--xxl {
    padding-top: 4.8rem;
  }
}

.pb-auto {
  padding-bottom: auto;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-8 {
  padding-bottom: 0.8rem;
}
.pb-16 {
  padding-bottom: 1.6rem;
}
.pb-20 {
  padding-bottom: 2rem;
}
.pb-24 {
  padding-bottom: 2.4rem;
}
.pb-40 {
  padding-bottom: 4rem;
}
.pb-48 {
  padding-bottom: 4.8rem;
}
@media only screen and (min-width: 360px) {
  .pb-auto--xs {
    padding-bottom: auto;
  }
  .pb-0--xs {
    padding-bottom: 0;
  }
  .pb-8--xs {
    padding-bottom: 0.8rem;
  }
  .pb-16--xs {
    padding-bottom: 1.6rem;
  }
  .pb-20--xs {
    padding-bottom: 2rem;
  }
  .pb-24--xs {
    padding-bottom: 2.4rem;
  }
  .pb-40--xs {
    padding-bottom: 4rem;
  }
  .pb-48--xs {
    padding-bottom: 4.8rem;
  }
}
@media only screen and (min-width: 640px) {
  .pb-auto--sm {
    padding-bottom: auto;
  }
  .pb-0--sm {
    padding-bottom: 0;
  }
  .pb-8--sm {
    padding-bottom: 0.8rem;
  }
  .pb-16--sm {
    padding-bottom: 1.6rem;
  }
  .pb-20--sm {
    padding-bottom: 2rem;
  }
  .pb-24--sm {
    padding-bottom: 2.4rem;
  }
  .pb-40--sm {
    padding-bottom: 4rem;
  }
  .pb-48--sm {
    padding-bottom: 4.8rem;
  }
}
@media only screen and (min-width: 720px) {
  .pb-auto--md {
    padding-bottom: auto;
  }
  .pb-0--md {
    padding-bottom: 0;
  }
  .pb-8--md {
    padding-bottom: 0.8rem;
  }
  .pb-16--md {
    padding-bottom: 1.6rem;
  }
  .pb-20--md {
    padding-bottom: 2rem;
  }
  .pb-24--md {
    padding-bottom: 2.4rem;
  }
  .pb-40--md {
    padding-bottom: 4rem;
  }
  .pb-48--md {
    padding-bottom: 4.8rem;
  }
}
@media only screen and (min-width: 960px) {
  .pb-auto--lg {
    padding-bottom: auto;
  }
  .pb-0--lg {
    padding-bottom: 0;
  }
  .pb-8--lg {
    padding-bottom: 0.8rem;
  }
  .pb-16--lg {
    padding-bottom: 1.6rem;
  }
  .pb-20--lg {
    padding-bottom: 2rem;
  }
  .pb-24--lg {
    padding-bottom: 2.4rem;
  }
  .pb-40--lg {
    padding-bottom: 4rem;
  }
  .pb-48--lg {
    padding-bottom: 4.8rem;
  }
}
@media only screen and (min-width: 1280px) {
  .pb-auto--xl {
    padding-bottom: auto;
  }
  .pb-0--xl {
    padding-bottom: 0;
  }
  .pb-8--xl {
    padding-bottom: 0.8rem;
  }
  .pb-16--xl {
    padding-bottom: 1.6rem;
  }
  .pb-20--xl {
    padding-bottom: 2rem;
  }
  .pb-24--xl {
    padding-bottom: 2.4rem;
  }
  .pb-40--xl {
    padding-bottom: 4rem;
  }
  .pb-48--xl {
    padding-bottom: 4.8rem;
  }
}
@media only screen and (min-width: 1600px) {
  .pb-auto--xxl {
    padding-bottom: auto;
  }
  .pb-0--xxl {
    padding-bottom: 0;
  }
  .pb-8--xxl {
    padding-bottom: 0.8rem;
  }
  .pb-16--xxl {
    padding-bottom: 1.6rem;
  }
  .pb-20--xxl {
    padding-bottom: 2rem;
  }
  .pb-24--xxl {
    padding-bottom: 2.4rem;
  }
  .pb-40--xxl {
    padding-bottom: 4rem;
  }
  .pb-48--xxl {
    padding-bottom: 4.8rem;
  }
}

.pl-auto {
  padding-left: auto;
}
.pl-0 {
  padding-left: 0;
}
.pl-8 {
  padding-left: 0.8rem;
}
.pl-16 {
  padding-left: 1.6rem;
}
.pl-20 {
  padding-left: 2rem;
}
.pl-24 {
  padding-left: 2.4rem;
}
.pl-40 {
  padding-left: 4rem;
}
.pl-48 {
  padding-left: 4.8rem;
}
@media only screen and (min-width: 360px) {
  .pl-auto--xs {
    padding-left: auto;
  }
  .pl-0--xs {
    padding-left: 0;
  }
  .pl-8--xs {
    padding-left: 0.8rem;
  }
  .pl-16--xs {
    padding-left: 1.6rem;
  }
  .pl-20--xs {
    padding-left: 2rem;
  }
  .pl-24--xs {
    padding-left: 2.4rem;
  }
  .pl-40--xs {
    padding-left: 4rem;
  }
  .pl-48--xs {
    padding-left: 4.8rem;
  }
}
@media only screen and (min-width: 640px) {
  .pl-auto--sm {
    padding-left: auto;
  }
  .pl-0--sm {
    padding-left: 0;
  }
  .pl-8--sm {
    padding-left: 0.8rem;
  }
  .pl-16--sm {
    padding-left: 1.6rem;
  }
  .pl-20--sm {
    padding-left: 2rem;
  }
  .pl-24--sm {
    padding-left: 2.4rem;
  }
  .pl-40--sm {
    padding-left: 4rem;
  }
  .pl-48--sm {
    padding-left: 4.8rem;
  }
}
@media only screen and (min-width: 720px) {
  .pl-auto--md {
    padding-left: auto;
  }
  .pl-0--md {
    padding-left: 0;
  }
  .pl-8--md {
    padding-left: 0.8rem;
  }
  .pl-16--md {
    padding-left: 1.6rem;
  }
  .pl-20--md {
    padding-left: 2rem;
  }
  .pl-24--md {
    padding-left: 2.4rem;
  }
  .pl-40--md {
    padding-left: 4rem;
  }
  .pl-48--md {
    padding-left: 4.8rem;
  }
}
@media only screen and (min-width: 960px) {
  .pl-auto--lg {
    padding-left: auto;
  }
  .pl-0--lg {
    padding-left: 0;
  }
  .pl-8--lg {
    padding-left: 0.8rem;
  }
  .pl-16--lg {
    padding-left: 1.6rem;
  }
  .pl-20--lg {
    padding-left: 2rem;
  }
  .pl-24--lg {
    padding-left: 2.4rem;
  }
  .pl-40--lg {
    padding-left: 4rem;
  }
  .pl-48--lg {
    padding-left: 4.8rem;
  }
}
@media only screen and (min-width: 1280px) {
  .pl-auto--xl {
    padding-left: auto;
  }
  .pl-0--xl {
    padding-left: 0;
  }
  .pl-8--xl {
    padding-left: 0.8rem;
  }
  .pl-16--xl {
    padding-left: 1.6rem;
  }
  .pl-20--xl {
    padding-left: 2rem;
  }
  .pl-24--xl {
    padding-left: 2.4rem;
  }
  .pl-40--xl {
    padding-left: 4rem;
  }
  .pl-48--xl {
    padding-left: 4.8rem;
  }
}
@media only screen and (min-width: 1600px) {
  .pl-auto--xxl {
    padding-left: auto;
  }
  .pl-0--xxl {
    padding-left: 0;
  }
  .pl-8--xxl {
    padding-left: 0.8rem;
  }
  .pl-16--xxl {
    padding-left: 1.6rem;
  }
  .pl-20--xxl {
    padding-left: 2rem;
  }
  .pl-24--xxl {
    padding-left: 2.4rem;
  }
  .pl-40--xxl {
    padding-left: 4rem;
  }
  .pl-48--xxl {
    padding-left: 4.8rem;
  }
}

.pr-auto {
  padding-right: auto;
}
.pr-0 {
  padding-right: 0;
}
.pr-8 {
  padding-right: 0.8rem;
}
.pr-16 {
  padding-right: 1.6rem;
}
.pr-20 {
  padding-right: 2rem;
}
.pr-24 {
  padding-right: 2.4rem;
}
.pr-40 {
  padding-right: 4rem;
}
.pr-48 {
  padding-right: 4.8rem;
}
@media only screen and (min-width: 360px) {
  .pr-auto--xs {
    padding-right: auto;
  }
  .pr-0--xs {
    padding-right: 0;
  }
  .pr-8--xs {
    padding-right: 0.8rem;
  }
  .pr-16--xs {
    padding-right: 1.6rem;
  }
  .pr-20--xs {
    padding-right: 2rem;
  }
  .pr-24--xs {
    padding-right: 2.4rem;
  }
  .pr-40--xs {
    padding-right: 4rem;
  }
  .pr-48--xs {
    padding-right: 4.8rem;
  }
}
@media only screen and (min-width: 640px) {
  .pr-auto--sm {
    padding-right: auto;
  }
  .pr-0--sm {
    padding-right: 0;
  }
  .pr-8--sm {
    padding-right: 0.8rem;
  }
  .pr-16--sm {
    padding-right: 1.6rem;
  }
  .pr-20--sm {
    padding-right: 2rem;
  }
  .pr-24--sm {
    padding-right: 2.4rem;
  }
  .pr-40--sm {
    padding-right: 4rem;
  }
  .pr-48--sm {
    padding-right: 4.8rem;
  }
}
@media only screen and (min-width: 720px) {
  .pr-auto--md {
    padding-right: auto;
  }
  .pr-0--md {
    padding-right: 0;
  }
  .pr-8--md {
    padding-right: 0.8rem;
  }
  .pr-16--md {
    padding-right: 1.6rem;
  }
  .pr-20--md {
    padding-right: 2rem;
  }
  .pr-24--md {
    padding-right: 2.4rem;
  }
  .pr-40--md {
    padding-right: 4rem;
  }
  .pr-48--md {
    padding-right: 4.8rem;
  }
}
@media only screen and (min-width: 960px) {
  .pr-auto--lg {
    padding-right: auto;
  }
  .pr-0--lg {
    padding-right: 0;
  }
  .pr-8--lg {
    padding-right: 0.8rem;
  }
  .pr-16--lg {
    padding-right: 1.6rem;
  }
  .pr-20--lg {
    padding-right: 2rem;
  }
  .pr-24--lg {
    padding-right: 2.4rem;
  }
  .pr-40--lg {
    padding-right: 4rem;
  }
  .pr-48--lg {
    padding-right: 4.8rem;
  }
}
@media only screen and (min-width: 1280px) {
  .pr-auto--xl {
    padding-right: auto;
  }
  .pr-0--xl {
    padding-right: 0;
  }
  .pr-8--xl {
    padding-right: 0.8rem;
  }
  .pr-16--xl {
    padding-right: 1.6rem;
  }
  .pr-20--xl {
    padding-right: 2rem;
  }
  .pr-24--xl {
    padding-right: 2.4rem;
  }
  .pr-40--xl {
    padding-right: 4rem;
  }
  .pr-48--xl {
    padding-right: 4.8rem;
  }
}
@media only screen and (min-width: 1600px) {
  .pr-auto--xxl {
    padding-right: auto;
  }
  .pr-0--xxl {
    padding-right: 0;
  }
  .pr-8--xxl {
    padding-right: 0.8rem;
  }
  .pr-16--xxl {
    padding-right: 1.6rem;
  }
  .pr-20--xxl {
    padding-right: 2rem;
  }
  .pr-24--xxl {
    padding-right: 2.4rem;
  }
  .pr-40--xxl {
    padding-right: 4rem;
  }
  .pr-48--xxl {
    padding-right: 4.8rem;
  }
}

.clamp {
  display: -webkit-box;
  -webkit-line-clamp: var(--clamp-line-clamp, 1);
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.container {
  width: var(--container-width, 100%);
  height: var(--container-height, auto);
  max-width: 100%;
  padding: var(--container-padding, 0 2.4rem);
  margin: 0 auto;
}

.card, .card--task {
  background-color: var(--color-neutral100);
  box-shadow: var(--shadow-down);
}

.card__header {
  padding: var(--card-header-padding, 2rem 2rem 0);
}

.card__body {
  padding: var(--card-body-padding, 2rem);
}

.card__footer {
  padding: var(--card-footer-padding, 0 2rem 2rem);
}

.card__actions {
  padding: var(--card-actions-padding, 1rem 2rem);
  border-top: var(--card-actions-border, 1px solid var(--color-neutral300));
}

.form__fields {
  display: grid;
  gap: 1.6rem;
}

.form__field {
  display: grid;
  gap: var(--form-field-gap, 0.8rem);
}

.form-validation-error {
  color: var(--color-error);
  font-size: small;
  margin: 0;
}

.label {
  display: block;
  width: 100%;
}

.input--text-has-error, .has-error .input--textarea, .has-error .input--text {
  border-color: var(--color-error);
  outline: 1px solid var(--color-error);
}

.input--text {
  display: block;
  width: var(--input-text-width, 100%);
  height: var(--input-text-height, 4.4rem);
  margin: 0;
  padding: var(--input-text-padding, 0 1.6rem);
  border: 1px solid var(--color-neutral300);
  outline: 0;
  background-color: var(--input-text-background-color, var(--color-neutral100));
  font: inherit;
  font-size: var(--input-text-font-size);
  font-weight: var(--input-text-font-weight);
  text-align: left;
  line-height: var(--input-text-line-height);
  color: var(--input-text-color);
}
.input--text[disabled] {
  background-color: var(--color-neutral200);
  color: var(--color-neutral600);
}
.input--textarea {
  display: block;
  width: var(--input-textarea-width, 100%);
  height: var(--input-textarea-height, 8.8rem);
  margin: 0;
  padding: var(--input-textarea-padding, 1.6rem);
  border: 1px solid var(--color-neutral300);
  outline: 0;
  background-color: var(--input-textarea-background-color, var(--color-neutral100));
  font: inherit;
  font-size: var(--input-textarea-font-size);
  font-weight: var(--input-textarea-font-weight);
  text-align: left;
  line-height: var(--input-textarea-line-height);
  color: var(--input-textarea-color);
}
.input--textarea[disabled] {
  background-color: var(--color-neutral200);
  color: var(--color-neutral600);
}
.input--toggle {
  position: relative;
  display: inline-grid;
  align-content: center;
  vertical-align: middle;
  width: 4.8rem;
  height: 3.2rem;
  padding: 0.4rem;
  border-radius: 1.6rem;
  background-color: var(--color-neutral200);
  transition: background-color 0.2s ease-in-out;
}
.input--toggle:has(:checked) {
  background-color: var(--input-toggle-background-color, var(--color-success));
}
.input--toggle:hover {
  cursor: pointer;
}
.input--toggle > input[type=checkbox] {
  position: absolute;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  overflow: hidden;
}
.input--toggle > input[type=checkbox]:checked + span {
  transform: translateX(1.6rem);
}
.input--toggle span {
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 1.2rem;
  background-color: var(--color-neutral100);
  box-shadow: var(--shadow-down);
  transition: transform 0.2s ease-in-out;
}

.input--option {
  width: var(--input-option-width, 1.6rem);
  height: var(--input-option-height, 1.6rem);
  margin: 0;
  accent-color: var(--input-option-accent-color, auto);
}

.btn, .menu__item, .btn--google-maps, .btn--floating-action, .btn--icon, .btn--lightgrey, .btn--white, .btn--yellow__reverse, .btn--yellow {
  position: relative;
  z-index: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: var(--btn-gap, 0.8rem);
  width: var(--btn-width, auto);
  height: var(--btn-height, 4.4rem);
  padding: var(--btn-padding, 0 2.4rem);
  border: 0;
  outline: 0;
  border-radius: var(--btn-border-radius, 0.5rem);
  background-color: var(--btn-background-color, transparent);
  font: inherit;
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  line-height: var(--btn-line-height);
  color: var(--btn-color);
  white-space: nowrap;
  overflow: hidden;
}
.btn[disabled], [disabled].menu__item, [disabled].btn--google-maps, [disabled].btn--floating-action, [disabled].btn--icon, [disabled].btn--lightgrey, [disabled].btn--white, [disabled].btn--yellow__reverse, [disabled].btn--yellow {
  background-color: var(--color-neutral200);
  color: var(--color-neutral600);
}
.btn[disabled]:before, [disabled].menu__item:before, [disabled].btn--google-maps:before, [disabled].btn--floating-action:before, [disabled].btn--icon:before, [disabled].btn--lightgrey:before, [disabled].btn--white:before, [disabled].btn--yellow__reverse:before, [disabled].btn--yellow:before {
  content: none;
}
.btn[disabled]:hover, [disabled].menu__item:hover, [disabled].btn--google-maps:hover, [disabled].btn--floating-action:hover, [disabled].btn--icon:hover, [disabled].btn--lightgrey:hover, [disabled].btn--white:hover, [disabled].btn--yellow__reverse:hover, [disabled].btn--yellow:hover {
  cursor: not-allowed;
}
.btn:before, .menu__item:before, .btn--google-maps:before, .btn--floating-action:before, .btn--icon:before, .btn--lightgrey:before, .btn--white:before, .btn--yellow__reverse:before, .btn--yellow:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: background-color 0.2s ease-in-out;
}
.btn:hover, .menu__item:hover, .btn--google-maps:hover, .btn--floating-action:hover, .btn--icon:hover, .btn--lightgrey:hover, .btn--white:hover, .btn--yellow__reverse:hover, .btn--yellow:hover {
  cursor: pointer;
}
.btn:hover:before, .menu__item:hover:before, .btn--google-maps:hover:before, .btn--floating-action:hover:before, .btn--icon:hover:before, .btn--lightgrey:hover:before, .btn--white:hover:before, .btn--yellow__reverse:hover:before, .btn--yellow:hover:before, .btn.is-active:before, .is-active.menu__item:before, .is-active.btn--google-maps:before, .is-active.btn--floating-action:before, .is-active.btn--icon:before, .is-active.btn--lightgrey:before, .is-active.btn--white:before, .is-active.btn--yellow__reverse:before, .is-active.btn--yellow:before {
  background-color: var(--btn-background-color-hover, rgba(0, 0, 0, 0.05));
}
.btn > *:has(svg), .menu__item > *:has(svg), .btn--google-maps > *:has(svg), .btn--floating-action > *:has(svg), .btn--icon > *:has(svg), .btn--lightgrey > *:has(svg), .btn--white > *:has(svg), .btn--yellow__reverse > *:has(svg), .btn--yellow > *:has(svg),
.btn > *:has(img),
.menu__item > *:has(img),
.btn--google-maps > *:has(img),
.btn--floating-action > *:has(img),
.btn--icon > *:has(img),
.btn--lightgrey > *:has(img),
.btn--white > *:has(img),
.btn--yellow__reverse > *:has(img),
.btn--yellow > *:has(img) {
  flex: 0 0 auto;
}
.btn > img, .menu__item > img, .btn--google-maps > img, .btn--floating-action > img, .btn--icon > img, .btn--lightgrey > img, .btn--white > img, .btn--yellow__reverse > img, .btn--yellow > img,
.btn > svg,
.menu__item > svg,
.btn--google-maps > svg,
.btn--floating-action > svg,
.btn--icon > svg,
.btn--lightgrey > svg,
.btn--white > svg,
.btn--yellow__reverse > svg,
.btn--yellow > svg {
  width: var(--btn-icon-width, 1.6rem);
  height: var(--btn-icon-height, 1.6rem);
  fill: var(--btn-icon-fill, currentColor);
}

.table-wrapper {
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

td {
  padding: 0.8rem 1.6rem;
}

.badge, .badge--task, .badge--success {
  width: var(--badge-width, auto);
  height: var(--badge-height, 3.2rem);
  border-radius: calc(var(--badge-height, 3.2rem) / 2);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: var(--badge-padding, 0 1.6rem);
  background-color: var(--badge-background-color, var(--color-neutral200));
  font: inherit;
  font-size: var(--badge-font-size);
  font-weight: var(--badge-font-weight);
  line-height: 100%;
  color: var(--badge-color);
  white-space: nowrap;
  overflow: hidden;
}
@media (max-width: 640px) {
  .badge, .badge--task, .badge--success {
    --badge-height: 2.4rem;
    --badge-padding: 0.8rem;
  }
}

.accordion__summary {
  display: block;
  list-style: none;
}
.accordion__summary::-webkit-details-marker {
  display: none;
}
.accordion__summary:hover {
  cursor: pointer;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

html,
body {
  width: 100%;
  height: 100%;
  overscroll-behavior: none;
}

body {
  background-color: white;
  font-family: var(--font-family, sans-serif);
  font-size: var(--font-size, 1.6rem);
  font-weight: var(--font-weight, 400);
  line-height: var(--line-height, 150%);
  color: var(--color, black);
  overflow: hidden;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

svg,
img {
  display: block;
  max-width: 100%;
  height: auto;
}

:root {
  --font-family: "Fira Sans", sans-serif;
  --font-size: 1.4rem;
  --font-weight: 400;
  --line-height: 150%;
  --color: var(--color-neutral900);
}

:root {
  --color-yellow: #ffe137;
  --color-beige: #ffe6aa;
  --color-sand: #fffaeb;
  --color-green: #173232;
  --color-red: #3c1e28;
  --color-orange: #f06233;
  --color-blue: #003c8c;
  --color-neutral100: #ffffff;
  --color-neutral200: #f0f0f0;
  --color-neutral300: #d0d0d0;
  --color-neutral400: #a0a0a0;
  --color-neutral500: #909090;
  --color-neutral600: #707070;
  --color-neutral700: #505050;
  --color-neutral800: #303030;
  --color-neutral900: #101010;
  --color-success: #2ba712;
  --color-info: #2e5ded;
  --color-warning: #ffc83e;
  --color-error: #ed2a2a;
  --color-cancelled: #ff0000;
  --color-finished: #00cc66;
  --color-in-progress: #ff9900;
  --color-default: #000000;
}

:root {
  --shadow-up: 0 -0.1rem 0.1rem hsl(0deg 0% 0% / 0.01),
    0 -0.2rem 0.2rem hsl(0deg 0% 0% / 0.01),
    0 -0.4rem 0.4rem hsl(0deg 0% 0% / 0.01),
    0 -0.8rem 0.8rem hsl(0deg 0% 0% / 0.01);
  --shadow-down: 0 0.1rem 0.1rem hsl(0deg 0% 0% / 0.01),
    0 0.2rem 0.2rem hsl(0deg 0% 0% / 0.01),
    0 0.4rem 0.4rem hsl(0deg 0% 0% / 0.01),
    0 0.8rem 0.8rem hsl(0deg 0% 0% / 0.01);
}

@keyframes inProgress {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.input--text {
  --input-text-height: 4rem;
}

.input--textarea {
  --input-textarea-height: calc(4rem * 2);
}

.input--option {
  --input-option-width: 2rem;
  --input-option-height: 2rem;
  --input-option-accent-color: var(--color-info);
}

.btn, .menu__item, .btn--google-maps, .btn--floating-action, .btn--icon, .btn--lightgrey, .btn--white, .btn--yellow__reverse, .btn--yellow {
  --btn-border-radius: 0;
  --btn-padding: 0 2rem;
  --btn-height: 4rem;
  --btn-font-weight: 500;
  --btn-color: var(--color-neutral900);
}
@media (max-width: 640px) {
  .btn > span, .menu__item > span, .btn--google-maps > span, .btn--floating-action > span, .btn--icon > span, .btn--lightgrey > span, .btn--white > span, .btn--yellow__reverse > span, .btn--yellow > span {
    display: none;
  }
  .btn:has(> span), .menu__item:has(> span), .btn--google-maps:has(> span), .btn--floating-action:has(> span), .btn--icon:has(> span), .btn--lightgrey:has(> span), .btn--white:has(> span), .btn--yellow__reverse:has(> span), .btn--yellow:has(> span) {
    --btn-width: 4rem;
    --btn-padding: 0;
  }
}

.btn--yellow {
  --btn-background-color: var(--color-yellow);
}

.btn--yellow__reverse {
  --btn-background-color: var(--color-neutral100);
}
.btn--yellow__reverse[disabled] {
  background-color: var(--color-yellow);
  color: var(--color-neutral900);
}

.btn--white {
  --btn-background-color: var(--color-neutral100);
}

.btn--lightgrey {
  --btn-background-color: var(--color-neutral200);
}

.btn--icon {
  --btn-width: 4rem;
  --btn-padding: 0;
}

.btn--floating-action {
  --btn-width: 4.8rem;
  --btn-height: 4.8rem;
  --btn-padding: 0;
  --btn-background-color: var(--color-yellow);
  box-shadow: var(--shadow-down);
}

.btn--google-maps {
  --btn-padding: 0;
  --btn-width: 3.2rem;
  --btn-height: 3.2rem;
  --btn-icon-width: 3.2rem;
  --btn-icon-height: 3.2rem;
}
.btn--google-maps:hover:before, .btn--google-maps.is-active:before {
  --btn-background-color-hover: transparent;
}

.card__header {
  --card-header-padding: 1rem 2rem 0;
  display: flex;
  align-items: center;
  min-height: 5rem;
}
.card__header:has(* + *) {
  justify-content: space-between;
  gap: 1.6rem;
}
.card__header:has(.btn--icon) {
  --card-header-padding: 1rem 1rem 0 2rem;
}

.card--task {
  border-left: 5px solid;
  border-color: var(--status-color);
  cursor: pointer;
}

.data-list {
  display: grid;
  grid-template-columns: repeat(var(--data-list-columns), minmax(0, 1fr));
  gap: 0.8rem 2rem;
}
.data-list > dl {
  padding: 0;
  margin: 0;
}
.data-list > dl > dt {
  padding: 0;
  margin: 0;
  color: var(--color-neutral500);
}
.data-list > dl > dd {
  padding: 0;
  margin: 0;
}
.data-list > dl > dd + dt {
  margin-top: var(--data-list-margin, 0.8rem);
}

.badge--success {
  background-color: var(--color-success);
  color: var(--color-neutral100);
}

.badge--task {
  background-color: var(--status-color, transparent);
  color: var(--color-neutral100);
}

.menu {
  display: flex;
  flex-direction: column;
}

.menu__item {
  --btn-height: 4.4rem;
  --btn-font-weight: normal;
  justify-content: flex-start;
}

.attachments {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
  gap: 1.6rem;
}

.attachment {
  display: grid;
  grid-template-columns: 12rem minmax(0, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-template-areas: "image body";
  border: 1px solid var(--color-neutral300);
}

.attachment__image {
  grid-area: image;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  padding: 0.8rem;
  cursor: pointer;
}

.attachment__icon {
  grid-area: image;
  --icon-width: 3.2rem;
  --icon-height: 3.2rem;
  padding: 0.8rem;
  width: 100%;
  aspect-ratio: 1;
  background-color: var(--color-neutral200);
  display: grid;
  justify-content: center;
  align-content: center;
}

.attachment__body {
  grid-area: body;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1.6rem;
  background-color: var(--color-neutral100);
}

.attachment__delete {
  grid-area: body;
  justify-self: end;
  margin: 0.8rem 0.8rem 0 0;
}

.attachment__download {
  grid-area: body;
  justify-self: end;
  margin: 0.8rem 0.8rem 0 0;
}

.rectangle {
  position: relative;
  width: var(--rectangle-width, 100%);
  height: var(--rectangle-height, auto);
  aspect-ratio: var(--rectangle-aspect-ratio, 1);
}
.rectangle > * {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.accordion__summary {
  display: flex;
  align-items: center;
  gap: 1rem;
}

[open] > .accordion__summary > .accordion__icon {
  transform: rotate(180deg);
}

.table > tbody > tr:not(:first-child) {
  border-top: 1px dashed var(--color-neutral400);
}
.table > tbody > tr:first-child {
  border-top: 1px dashed var(--color-neutral400);
}
.table > tbody > tr:last-child {
  border-bottom: 1px dashed var(--color-neutral400);
}

body {
  --font-size: 1.6rem;
  --line-height: 150%;
  background-color: var(--color-sand);
}
@media (max-width: 640px) {
  body {
    --font-size: 1.4rem;
    --line-height: 150%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

h1 {
  font-size: 3.2rem;
  line-height: 125%;
}
@media (max-width: 640px) {
  h1 {
    font-size: 2.4rem;
    line-height: 125%;
  }
}

h2 {
  font-size: 2.4rem;
  line-height: 125%;
}
@media (max-width: 640px) {
  h2 {
    font-size: 2rem;
    line-height: 125%;
  }
}

h3 {
  font-size: 2rem;
  line-height: 125%;
}
@media (max-width: 640px) {
  h3 {
    font-size: 1.6rem;
    line-height: 125%;
  }
}

small {
  font-size: 75%;
  line-height: 150%;
}