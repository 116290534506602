.table {
    > tbody > tr {
        &:not(:first-child) {
            border-top: 1px dashed var(--color-neutral400);
        }

        &:first-child {
            border-top: 1px dashed var(--color-neutral400);
        }

        &:last-child {
            border-bottom: 1px dashed var(--color-neutral400);
        }
    }
}
