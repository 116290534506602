.menu {
  display: flex;
  flex-direction: column;
}

.menu__item {
    @extend .btn;
    --btn-height: 4.4rem;
    --btn-font-weight: normal;
    justify-content: flex-start;
}
