:root {
    --color-yellow: #ffe137;
    --color-beige: #ffe6aa;
    --color-sand: #fffaeb;
    --color-green: #173232;
    --color-red: #3c1e28;
    --color-orange: #f06233;
    --color-blue: #003c8c;

    --color-neutral100: #ffffff;
    --color-neutral200: #f0f0f0; /// LIGHT BACKGROUND
    --color-neutral300: #d0d0d0; /// BORDER & SELECTED STATE
    --color-neutral400: #a0a0a0;
    --color-neutral500: #909090;
    --color-neutral600: #707070;
    --color-neutral700: #505050;
    --color-neutral800: #303030;
    --color-neutral900: #101010;

    --color-success: #2ba712;
    --color-info: #2e5ded;
    --color-warning: #ffc83e;
    --color-error: #ed2a2a;

    --color-cancelled: #ff0000;
    --color-finished: #00cc66;
    --color-in-progress: #ff9900;
    --color-default: #000000;
}
