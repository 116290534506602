.btn {
    position: relative;
    z-index: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: var(--btn-gap, 0.8rem);
    width: var(--btn-width, auto);
    height: var(--btn-height, 4.4rem);
    padding: var(--btn-padding, 0 2.4rem);
    border: 0;
    outline: 0;
    border-radius: var(--btn-border-radius, 0.5rem);
    background-color: var(--btn-background-color, transparent);
    font: inherit;
    font-size: var(--btn-font-size);
    font-weight: var(--btn-font-weight);
    line-height: var(--btn-line-height);
    color: var(--btn-color);
    white-space: nowrap;
    overflow: hidden;

    &[disabled] {
        background-color: var(--color-neutral200);
        color: var(--color-neutral600);

        &:before {
            content: none;
        }

        &:hover {
            cursor: not-allowed;
        }
    }

    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: background-color 0.2s ease-in-out;
    }

    &:hover {
        cursor: pointer;
    }

    &:hover:before,
    &.is-active:before {
        background-color: var(--btn-background-color-hover, #{rgba(black, 0.05)});
    }

    > *:has(svg),
    > *:has(img) {
        flex: 0 0 auto;
    }

    > img,
    > svg {
        width: var(--btn-icon-width, 1.6rem);
        height: var(--btn-icon-height, 1.6rem);
        fill: var(--btn-icon-fill, currentColor);
    }
}
