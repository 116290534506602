.p {
  @each $unit in map-keys($spacing) {
    &-#{$unit} {
      padding-top: map-get($spacing, $unit);
      padding-bottom: map-get($spacing, $unit);
      padding-left: map-get($spacing, $unit);
      padding-right: map-get($spacing, $unit);
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @each $unit in map-keys($spacing) {
        &-#{$unit}--#{$breakpoint} {
          padding-top: map-get($spacing, $unit);
          padding-bottom: map-get($spacing, $unit);
          padding-left: map-get($spacing, $unit);
          padding-right: map-get($spacing, $unit);
        }
      }
    }
  }
}

.py {
  @each $unit in map-keys($spacing) {
    &-#{$unit} {
      padding-top: map-get($spacing, $unit);
      padding-bottom: map-get($spacing, $unit);
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @each $unit in map-keys($spacing) {
        &-#{$unit}--#{$breakpoint} {
          padding-top: map-get($spacing, $unit);
          padding-bottom: map-get($spacing, $unit);
        }
      }
    }
  }
}

.px {
  @each $unit in map-keys($spacing) {
    &-#{$unit} {
      padding-left: map-get($spacing, $unit);
      padding-right: map-get($spacing, $unit);
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @each $unit in map-keys($spacing) {
        &-#{$unit}--#{$breakpoint} {
          padding-left: map-get($spacing, $unit);
          padding-right: map-get($spacing, $unit);
        }
      }
    }
  }
}

.pt {
  @each $unit in map-keys($spacing) {
    &-#{$unit} {
      padding-top: map-get($spacing, $unit);
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @each $unit in map-keys($spacing) {
        &-#{$unit}--#{$breakpoint} {
          padding-top: map-get($spacing, $unit);
        }
      }
    }
  }
}

.pb {
  @each $unit in map-keys($spacing) {
    &-#{$unit} {
      padding-bottom: map-get($spacing, $unit);
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @each $unit in map-keys($spacing) {
        &-#{$unit}--#{$breakpoint} {
          padding-bottom: map-get($spacing, $unit);
        }
      }
    }
  }
}

.pl {
  @each $unit in map-keys($spacing) {
    &-#{$unit} {
      padding-left: map-get($spacing, $unit);
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @each $unit in map-keys($spacing) {
        &-#{$unit}--#{$breakpoint} {
          padding-left: map-get($spacing, $unit);
        }
      }
    }
  }
}

.pr {
  @each $unit in map-keys($spacing) {
    &-#{$unit} {
      padding-right: map-get($spacing, $unit);
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @each $unit in map-keys($spacing) {
        &-#{$unit}--#{$breakpoint} {
          padding-right: map-get($spacing, $unit);
        }
      }
    }
  }
}
