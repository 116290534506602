:root {
  --shadow-up: 0 -0.1rem 0.1rem hsl(0deg 0% 0% / 0.01),
    0 -0.2rem 0.2rem hsl(0deg 0% 0% / 0.01),
    0 -0.4rem 0.4rem hsl(0deg 0% 0% / 0.01),
    0 -0.8rem 0.8rem hsl(0deg 0% 0% / 0.01);
  --shadow-down: 0 0.1rem 0.1rem hsl(0deg 0% 0% / 0.01),
    0 0.2rem 0.2rem hsl(0deg 0% 0% / 0.01),
    0 0.4rem 0.4rem hsl(0deg 0% 0% / 0.01),
    0 0.8rem 0.8rem hsl(0deg 0% 0% / 0.01);
}
