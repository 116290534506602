.form__fields {
    display: grid;
    gap: 1.6rem;
}

.form__field {
    display: grid;
    gap: var(--form-field-gap, 0.8rem);
}
.form-validation-error {
    color: var(--color-error);
    font-size: small;
    margin: 0;
}

.label {
    display: block;
    width: 100%;
}

.input--text-has-error {
    border-color: var(--color-error);
    outline: 1px solid var(--color-error);
}

.input--text {
    display: block;
    width: var(--input-text-width, 100%);
    height: var(--input-text-height, 4.4rem);
    margin: 0;
    padding: var(--input-text-padding, 0 1.6rem);
    border: 1px solid var(--color-neutral300);
    outline: 0;
    background-color: var(--input-text-background-color, var(--color-neutral100));
    font: inherit;
    font-size: var(--input-text-font-size);
    font-weight: var(--input-text-font-weight);
    text-align: left;
    line-height: var(--input-text-line-height);
    color: var(--input-text-color);

    &[disabled] {
        background-color: var(--color-neutral200);
        color: var(--color-neutral600);
    }

    .has-error & {
        @extend .input--text-has-error;
    }
}

.input--textarea {
    display: block;
    width: var(--input-textarea-width, 100%);
    height: var(--input-textarea-height, calc(2 * 4.4rem));
    margin: 0;
    padding: var(--input-textarea-padding, 1.6rem);
    border: 1px solid var(--color-neutral300);
    outline: 0;
    background-color: var(--input-textarea-background-color, var(--color-neutral100));
    font: inherit;
    font-size: var(--input-textarea-font-size);
    font-weight: var(--input-textarea-font-weight);
    text-align: left;
    line-height: var(--input-textarea-line-height);
    color: var(--input-textarea-color);

    &[disabled] {
        background-color: var(--color-neutral200);
        color: var(--color-neutral600);
    }

    .has-error & {
        @extend .input--text-has-error;
    }
}

.input--toggle {
    position: relative;
    display: inline-grid;
    align-content: center;
    vertical-align: middle;
    width: 4.8rem;
    height: 3.2rem;
    padding: 0.4rem;
    border-radius: 1.6rem;
    background-color: var(--color-neutral200);
    transition: background-color 0.2s ease-in-out;

    &:has(:checked) {
        background-color: var(--input-toggle-background-color, var(--color-success));
    }

    &:hover {
        cursor: pointer;
    }

    > input[type="checkbox"] {
        position: absolute;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        width: 1px;
        height: 1px;
        white-space: nowrap;
        overflow: hidden;

        &:checked + span {
            transform: translateX(1.6rem);
        }
    }

    span {
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 1.2rem;
        background-color: var(--color-neutral100);
        box-shadow: var(--shadow-down);
        transition: transform 0.2s ease-in-out;
    }
}

.input--option {
    width: var(--input-option-width, 1.6rem);
    height: var(--input-option-height, 1.6rem);
    margin: 0;
    accent-color: var(--input-option-accent-color, auto);
}
