.input--text {
    --input-text-height: 4rem;
}

.input--textarea {
    --input-textarea-height: calc(4rem * 2);
}

.input--option {
    --input-option-width: 2rem;
    --input-option-height: 2rem;
    --input-option-accent-color: var(--color-info);
}
