.accordion__summary {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.accordion__icon {
    [open] > .accordion__summary > & {
        transform: rotate(180deg);
    }
}
