@use "sass:map";

.badge {
    width: var(--badge-width, auto);
    height: var(--badge-height, 3.2rem);
    border-radius: calc(var(--badge-height, 3.2rem) / 2);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: var(--badge-padding, 0 1.6rem);
    background-color: var(--badge-background-color, var(--color-neutral200));
    font: inherit;
    font-size: var(--badge-font-size);
    font-weight: var(--badge-font-weight);
    line-height: 100%;
    color: var(--badge-color);
    white-space: nowrap;
    overflow: hidden;

    @media (max-width: map.get($breakpoints, "sm")) {
        --badge-height: 2.4rem;
        --badge-padding: 0.8rem;
    }
}
