$columns: 12;

.grid {
  display: grid;
  grid-template-columns: repeat($columns, minmax(0, 1fr));
  grid-auto-rows: auto;
  gap: var(--grid-gap-y, var(--grid-gap, 2.4rem)) var(--grid-gap-x, var(--grid-gap, 2.4rem));
}

.grid__item {
  @for $i from 1 through $columns {
    &-#{$i} {
      grid-column: auto / span #{$i};
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @for $i from 1 through $columns {
        &-#{$i}--#{$breakpoint} {
          grid-column: auto / span #{$i};
        }
      }
    }
  }
}
