.rectangle {
    position: relative;
    width: var(--rectangle-width, 100%);
    height: var(--rectangle-height, auto);
    aspect-ratio: var(--rectangle-aspect-ratio, 1);

    > * {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}
